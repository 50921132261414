import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Map as CoreMap } from '@mappr/react-lib';
import { AppContext } from '../../../context';
import { getDomain } from '../../../utils/envUtils';
import { useHistory } from 'react-router-dom';
import { PoiPopUp } from './PopUp/PoiPopUp';
import SecondaryLayerPopUp from '../../../components/Map/SecondaryLayerPopUp/SecondaryLayerPopUp';
import { useParams } from 'react-router';

function Map({ projectConfigs, basemaps, active, journey,controlsToggle }) {
    const history = useHistory();
    const currentBaseMap = sessionStorage.getItem(
        `${getDomain()}-mpr-base-map`
    );
    const { page } = useParams();

    useEffect(() => {
        document
            .getElementById('axeptio_overlay')
            ?.style.removeProperty('z-index');
        if (page === 'map') {
            setTimeout(() => {
                const popup =
                    document.getElementById('MapPopup')?.children.length;
                if (popup) {
                    document.getElementById('axeptio_overlay').style.zIndex =
                        '1';
                }
            }, 200);
        }
    }, [page]);

    const [currentBasemapInfo, setCurrentBasemapInfo] = useState();
    const {
        mapCenter,
        mapZoom,
        mapZoomMobile,
        minZoom,
        minZoomMobile,
        maxZoom,
        maxZoomMobile,
        mapPitch,
        mapPitchMobile,
        minPitch,
        minPitchMobile,
        maxPitch,
        maxPitchMobile,
        boundingBox,
        isBboxIncluded,
        models,
    } = projectConfigs;

    useEffect(() => {
        if (currentBaseMap) {
            const basemapInfo = basemaps.filter(
                (basemap) => basemap.name === currentBaseMap
            );
            setCurrentBasemapInfo(basemapInfo[0]);
        }
    }, [basemaps, currentBaseMap]);

    const openStreetViewModal = (feature, methods) => {
        let obj = feature.properties;
        methods.setMedias([{ embedUrl: obj.URL }]);

        return false;
    };
    return (
        <div
            className={`container-fluid h-100 w-100  px-0 top-0 position-absolute ${
                !active ? 'page-hidden' : 'page-active'
            }`}
            onClick={() => {
                controlsToggle('');
            }}
        >
            <div className="w-100 h-100 d-flex flex-column justify-content-start align-items-stretch align-content-stretch flex-nowrap">
                <AppContext.Consumer>
                    {({ streetViewModal }) => {
                        return (
                            currentBasemapInfo && (
                                <CoreMap
                                    lng={
                                        mapCenter.longitude ||
                                        mapCenter.longitude
                                    }
                                    lat={
                                        mapCenter.latitude || mapCenter.latitude
                                    }
                                    zoom={mapZoomMobile || mapZoom}
                                    url={
                                        currentBasemapInfo &&
                                        currentBasemapInfo.url
                                    }
                                    models={models}
                                    openStreetViewModal={(feature) =>
                                        openStreetViewModal(feature, {
                                            setMedias:
                                                streetViewModal.setMedias,
                                        })
                                    }
                                    SecondaryLayerPopUpComponent={
                                        SecondaryLayerPopUp
                                    }
                                    isMobile={true}
                                    PopUpComponent={PoiPopUp}
                                    history={history}
                                    minZoom={minZoomMobile || minZoom}
                                    maxZoom={maxZoomMobile || maxZoom}
                                    pitch={mapPitchMobile || mapPitch || 45}
                                    minPitch={minPitchMobile || minPitch || 0}
                                    maxPitch={maxPitchMobile || maxPitch || 60}
                                    boundingBox={boundingBox}
                                    isBboxIncluded={isBboxIncluded !== false}
                                />
                            )
                        );
                    }}
                </AppContext.Consumer>
                {!journey && (
                    <div
                        style={{ bottom: '0', width: '100vw' }}
                        id={'mobilePopupContainer'}
                    ></div>
                )}
            </div>
        </div>
    );
}

Map.propTypes = {
    position: PropTypes.oneOf(['left', 'right', 'full']).isRequired,
    loading: PropTypes.bool,
    projectConfigs: PropTypes.object,
    basemaps: PropTypes.array,
    journey: PropTypes.string,
    openedControl: PropTypes.string,
    active: PropTypes.bool,
    handleMobilePage: PropTypes.func,
    controlsToggle: PropTypes.func,
};

Map.defaultProps = {
    position: 'left',
    loading: true,
};

export default Map;
