import React from 'react';
import { Accordion, Tab } from 'react-bootstrap';
import { Filters as CoreFilters } from '@mappr/react-lib';
import AfficherTab from './Tabs/AfficherTab';
import SecondaryLayerTab from './Tabs/SecondaryLayerTab';
import LineTab from './Tabs/LineTab';
import { useTranslation } from 'react-i18next';
import ListLoader from '../loaders/ListLoader/ListLoader';

const Line = () => {
    const { t } = useTranslation();
    return (
        <Tab.Pane eventKey="line">
            <Accordion defaultActiveKey={['0']} alwaysOpen>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{t('lines.lineTab')}</Accordion.Header>
                    <Accordion.Body>
                        <CoreFilters>
                            {(
                                {
                                    filters,
                                    loading,
                                    selectedValues,
                                    initialFilters,
                                },
                                { onToggle }
                            ) => {
                                const line = initialFilters.filter(
                                    (_) => _.name === 'lines'
                                )[0];
                                if (loading) {
                                    return <ListLoader />;
                                } else {
                                    return (
                                        <LineTab
                                            selectedValues={selectedValues}
                                            name={line?.name}
                                            stats={line?.stats}
                                            filters={filters}
                                            onToggle={onToggle}
                                        />
                                    );
                                }
                            }}
                        </CoreFilters>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                        {t('lines.worksTab.title')}
                    </Accordion.Header>
                    <Accordion.Body>
                        <CoreFilters>
                            {(
                                {
                                    filters,
                                    loading,
                                    selectedValues,
                                    initialFilters,
                                },
                                { onToggle }
                            ) => {
                                const line = initialFilters.filter(
                                    (_) => _.name === 'type'
                                )[0];
                                if (loading) {
                                    return <ListLoader />;
                                } else {
                                    return (
                                        <AfficherTab
                                            selectedValues={selectedValues}
                                            name={line?.name}
                                            stats={line?.stats}
                                            filters={filters}
                                            onToggle={onToggle}
                                        />
                                    );
                                }
                            }}
                        </CoreFilters>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <SecondaryLayerTab />
        </Tab.Pane>
    );
};

export default Line;
