import React from 'react';
import './Calendar.scss';
import { Filters as CoreFilters, Layers } from '@mappr/react-lib';
import PropTypes from 'prop-types';
import { CalendarItems } from '../../../../components/Calendar/CalendarItems';

export default function Calendar({ openedControls, controlsToggle }) {
    return (
      <CoreFilters>
          {({ selectedValues }, { onToggle }) => (
            <>
                <Layers group={'basemap'}>
                    {({ layers, activeLayers }, { toggleLayer }) => (
                      <CalendarItems
                        openedControls={openedControls}
                        controlsToggle={controlsToggle}
                        toggleLayer={toggleLayer}
                        layers={layers}
                        activeLayers={activeLayers}
                        onToggle={onToggle}
                        selectedValues={selectedValues}
                        closeCalendar={() => controlsToggle('')}
                      />
                    )}
                </Layers>
            </>
          )}
      </CoreFilters>
    );
}
Calendar.propTypes = {
    openedControls: PropTypes.string,
    controlsToggle: PropTypes.func,
};
