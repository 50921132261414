import React from 'react';
import { Form } from 'react-bootstrap';
import { Layers } from '@mappr/react-lib';
import { useTranslation } from 'react-i18next';

const SecondaryLayerTab = () => {
    const { t } = useTranslation();
    return (
        <div className="secondary-layer">
            <p>{t('lines.existingNetwork.title')}</p>

            <Layers group={'secondary'}>
                {({ activeLayers }, { toggleLayer }) => {
                    return (
                        <div>
                            <Form.Check
                                inline
                                label={t('lines.existingNetwork.metro')}
                                name="group1"
                                type="checkbox"
                                checked={activeLayers.includes('metroLayer')}
                                onChange={() =>
                                    toggleLayer('metroLayer', 'secondary')
                                }
                            />
                            <Form.Check
                                inline
                                label={t('lines.existingNetwork.tram')}
                                name="Tram"
                                checked={activeLayers.includes('trainLayer')}
                                onChange={() =>
                                    toggleLayer('trainLayer', 'secondary')
                                }
                            />
                            <Form.Check
                                inline
                                label={t('lines.existingNetwork.rer')}
                                type="checkbox"
                                checked={activeLayers.includes('rerLayer')}
                                onChange={() =>
                                    toggleLayer('rerLayer', 'secondary')
                                }
                            />
                        </div>
                    );
                }}
            </Layers>
        </div>
    );
};
export default SecondaryLayerTab;
