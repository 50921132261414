import React, {useContext, useEffect} from 'react';
import {Filters as CoreFilters, Layers} from '@mappr/react-lib';
import './LayersMenu.scss';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {AppContext} from '../../../context';
import {getHash} from '../../../utils/urlUtils';
import {Button} from 'react-bootstrap';
import CssFilterConverter from 'css-filter-converter';

function LayersMenu({openedControls, controlsToggle}) {
    const {t} = useTranslation();
    const {configModal, projectConfigs} = useContext(AppContext);
    const activeBasemap = getHash('basemap');
    const urlColorMode = getHash('color') && `#${getHash('color')}`;

    useEffect(() => {
        if (projectConfigs) {
            const {primaryColor} = projectConfigs;
            const result = CssFilterConverter.hexToFilter(urlColorMode || primaryColor);
            const root = document.documentElement;
            root?.style.setProperty(
                '--primary-color', urlColorMode || primaryColor
            );
            root?.style.setProperty(
                '--filter-svg-color', result?.color
            );
        }
    }, [projectConfigs, urlColorMode]);

    return (
        <CoreFilters>
            {/* eslint-disable-next-line no-empty-pattern */}
            {({}, {removeAllFilters}) => (
                <div>
                    <Button
                        variant="link"
                        className={`layers-menu-btn text-decoration-none ${
                            openedControls === 'layers' ? 'active' : ''
                        } ${openedControls === 'calendar' ? 'disabled' : ''}`}
                        onClick={() => {
                            removeAllFilters();
                            if (activeBasemap !== 'background-sgp-full') {
                                configModal.handleSave('background-sgp-full');
                            }
                            openedControls === 'layers'
                                ? controlsToggle('')
                                : controlsToggle('layers');
                        }}
                    >
                        <span className={'me-2'}/>
                        <p>Activités et bénéfices</p>
                    </Button>
                    {openedControls === 'layers' && (
                        <div className="layers-menu">
                            <Layers group={'secondary'}>
                                {(
                                    {layers, activeLayers},
                                    {toggleLayer}
                                ) => {
                                    return layers.map((section) => {
                                        if (
                                            section.id === '3' ||
                                            section.id === '4'
                                        )
                                            return null;
                                        let sectionHeader = null;
                                        if (section.id) {
                                            sectionHeader = (
                                                <div
                                                    key={`section_${section.id}`}
                                                    className="layers-menu__section"
                                                >
                                                    {t(
                                                        `project:map.layers.sections.section_${section.id}`
                                                    )}
                                                </div>
                                            );
                                        }

                                        let sectionLayers =
                                            section.secondaryLayers.map(
                                                (aLayer) => {
                                                    const inputId = `layer-${aLayer.name}-check`;
                                                    return aLayer.canBeToggled ? (
                                                        <div
                                                            key={aLayer.name}
                                                            className="layers-menu__layer"
                                                            onClick={() => {
                                                                toggleLayer(
                                                                    aLayer.name,
                                                                    'secondary'
                                                                );
                                                            }}
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                name={inputId}
                                                                id={inputId}
                                                                checked={activeLayers.includes(
                                                                    aLayer.name
                                                                )}
                                                                readOnly
                                                            />
                                                            <label
                                                                htmlFor={
                                                                    inputId
                                                                }
                                                            >
                                                                {t(
                                                                    `project:map.layers.${aLayer.name}`
                                                                )}
                                                            </label>
                                                        </div>
                                                    ) : null;
                                                }
                                            );

                                        return [
                                            sectionHeader,
                                            ...sectionLayers,
                                        ];
                                    });
                                }}
                            </Layers>
                            <div className={'layers-menu-close-btn'} onClick={() => {
                                controlsToggle('');
                            }}>
                                <div
                                    className="layers-menu__close"
                                />
                            </div>
                        </div>
                    )}
                </div>
            )}
        </CoreFilters>
    );
}

LayersMenu.propTypes = {
    openedControls: PropTypes.string,
    controlsToggle: PropTypes.func,
};
export default LayersMenu;
