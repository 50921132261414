import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Image, Row } from 'react-bootstrap';
import operationsCenter from '../../../assets/icons/operationsCenter.png';
import viaducts from '../../../assets/icons/viaducts.png';
import settings from '../../../assets/icons/settings.png';
import PropTypes from 'prop-types';

const Afficher = ({  onToggle, selectedValues }) => {
    const search = new URLSearchParams(window.location.hash.replace('#', ''));
    const { filters } = Object.fromEntries(search.entries());
    useEffect(() => {
        if (!filters || Object.keys(JSON.parse(filters)).length === 0) {
            onToggle('type', 'Gare');
        }
    }, [filters, onToggle]);

    const { t } = useTranslation();
    const handleCheckbox = useCallback(
        (item) => {
            onToggle('type', item);
        },
        [onToggle]
    );

    return (
        <>
            <Row className="mb-2">
                <Col md="10" sm={10} xs={10}>
                    <Form.Check
                        inline
                        label={t('lines.worksTab.operationsCenters')}
                        name="group1"
                        type="checkbox"
                        checked={
                            selectedValues &&
                            selectedValues?.type &&
                            selectedValues?.type.includes(
                                'Centre d\'exploitation'
                            )
                        }
                        onChange={() => {
                            handleCheckbox('Centre d\'exploitation');
                        }}
                    />
                </Col>

                <Col md={2} sm={2} xs={2}>
                    <Image src={operationsCenter} width={16} />
                </Col>
            </Row>
            <Row className="mb-2">
                <Col md="10" sm={10} xs={10}>
                    <Form.Check
                        inline
                        label={t('lines.worksTab.viaducs')}
                        name="group1"
                        type="checkbox"
                        checked={
                            selectedValues &&
                            selectedValues?.type &&
                            selectedValues?.type.includes('Viaduc')
                        }
                        onChange={() => {
                            handleCheckbox('Viaduc');
                        }}
                    />
                </Col>

                <Col md={2} sm={2} xs={2}>
                    <Image src={viaducts} width={16} />
                </Col>
            </Row>
            <Row className="mb-2">
                <Col md="10" sm={10} xs={10}>
                    <Form.Check
                        inline
                        name="group1"
                        label={t('lines.worksTab.serviceWorks')}
                        type="checkbox"
                        checked={
                            selectedValues &&
                            selectedValues?.type &&
                            selectedValues?.type.includes('Ouvrage')
                        }
                        onChange={() => {
                            handleCheckbox('Ouvrage');
                        }}
                    />
                </Col>

                <Col md={2} sm={2} xs={2}>
                    <Image src={settings} width={16} />
                </Col>
            </Row>
        </>
    );
};
Afficher.propTypes = {
    name: PropTypes.string,
    onToggle: PropTypes.func,
    selectedValues: PropTypes.object,
};
export default Afficher;
