import React, { useCallback } from 'react';
import Footer from '../../Footer/Footer';
import SidebarToggleButton from '../../../../components/SidebarToggleButton/SidebarToggleButton';
import PropTypes from 'prop-types';
import { MapContext } from '../../../../context';
import Header from '../../Header/Header';
import { POIPage } from '../../../../components/LineView/POIPage/POIPage';

const Poi = ({
    project,
    toggleMenu,
    primaryColor,
    isSidebarOpen,
    item,
    error,
    deselect,
}) => {
    const handleDeselect = useCallback((onDeselect, setMapItem) => {
        onDeselect();
        setMapItem && setMapItem('selected', null, {});
    }, []);

    if (!item) {
        return null;
    }
    if (error) {
        return <div className="notification is-danger">{error}</div>;
    }

    return (
        <>
            {isSidebarOpen ? (
                <>
                    <MapContext.Consumer>
                        {({ feature: { setMapItem } }) => {
                            return (
                                <Header
                                    projectConfigs={project.configs}
                                    deselect={() =>
                                        handleDeselect(deselect, setMapItem)
                                    }
                                />
                            );
                        }}
                    </MapContext.Consumer>
                    <POIPage project={project} item={item} />
                    <Footer
                        toggleMenu={toggleMenu}
                        primaryColor={primaryColor}
                    />
                </>
            ) : (
                <SidebarToggleButton
                    toggleMenu={toggleMenu}
                    isSidebarOpen={isSidebarOpen}
                />
            )}
        </>
    );
};
Poi.propTypes = {
    project: PropTypes.object,
    toggleMenu: PropTypes.func,
    primaryColor: PropTypes.string,
    isSidebarOpen: PropTypes.bool,
    item: PropTypes.object,
    error: PropTypes.object,
    deselect: PropTypes.func,
};
export default Poi;
