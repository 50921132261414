function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import TinyQueue from 'tinyqueue';
// eslint-disable-next-line no-unused-vars
import mapboxgl from 'mapbox-gl';
import {
// getModelConfig,
getModelPath, shouldRenderModel, shouldLoadModel, setModelOptions,
// meshRenderer,
// materialRenderer,
toggleModelsVisibility, positionModelsWithId, clearModelRemovalTimeout, createModelRemovalTimeout, disposeAllModels, disposeModelWithId,
// findModelForHighlight,
// highlightModelObject,
// clearModelHighlights,
findVisitPoi, addNewModel, shouldRenderCityModel, getFormattedModels } from './modelUtils';
import turfDistance from '@turf/distance';
import utils from './utils';
import Logger from 'js-logger';
import * as THREE from 'threebox-plugin/src/three';
import { Threebox } from 'threebox-plugin';

// eslint-disable-next-line no-unused-vars
var turfPoint = utils.turfPoint,
  intersectBbox = utils.intersectBbox,
  disposeObject = utils.disposeObject,
  disposeTree = utils.disposeTree;
var THREE_MODELS = window.THREE_MODELS = [];
var MODEL_IDS = {};
var LOADED_CUSTOM_LAYERS = [];
var LOADED_CITIES = {};
var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
var map;
var models = [];
var pegman = null;
var pegmanHoverColor = 'ec5260';
var resources = {
  summaryPols: 0,
  summarySize: 0
};
export default {
  onAdd: function onAdd(layerId, layerOptions, modelGroups, commonModels, cityModels, mpbxMap, mbxContext, isDemo, visible) {
    var _this = this;
    if (commonModels === void 0) {
      commonModels = [];
    }
    if (cityModels === void 0) {
      cityModels = [];
    }
    if (isDemo === void 0) {
      isDemo = false;
    }
    this.layerId = layerId;
    this.on = visible;
    Logger.debug('onAdd', modelGroups, commonModels, cityModels);
    map = window.map = mpbxMap;
    var THREE_BOX = window.THREE_BOX = new Threebox(mpbxMap, mbxContext, {
      defaultLights: true
    });
    window.tb = THREE_BOX;
    this.modelsToRender = [];
    THREE_BOX.renderer.gammaFactor = 2.5;
    THREE_BOX.renderer.outputEncoding = THREE.GammaEncoding;
    THREE_BOX.renderer.antialias = false;
    THREE_BOX.lights.ambientLight.intensity = 0.6;
    THREE_BOX.lights.dirLight.intensity = 0.5;
    THREE_BOX.lights.dirLightBack.intensity = 0.5;
    this.allModelsIds = {};
    this.cityModels = cityModels;
    if (LOADED_CUSTOM_LAYERS.includes(layerId)) {
      return;
    }
    if (!modelGroups || !Array.isArray(modelGroups) || !modelGroups.length) {
      return;
    }
    if (layerOptions.globalModelToggle) {
      models = modelGroups[this.activeModelGroup || 0];
    } else {
      models = commonModels.concat(modelGroups[this.activeModelGroup || 0]);
    }
    if (!models || !Array.isArray(models)) {
      return;
    }
    function addModelsToGlobalList(modelsList, allModelsIds, modelsToRender, commonModelSettings) {
      modelsList.forEach(function (model) {
        // eslint-disable-next-line no-prototype-builtins
        if (allModelsIds.hasOwnProperty(model.id)) {
          allModelsIds[model.id]++;
          model.id = model.id + '-' + allModelsIds[model.id];
        } else {
          allModelsIds[model.id] = 0;
        }
        addNewModel(model, THREE_MODELS, MODEL_IDS, modelsToRender, commonModelSettings);
      });
    }
    addModelsToGlobalList(models, this.allModelsIds, this.modelsToRender);
    cityModels.forEach(function (aCityModels) {
      addModelsToGlobalList(aCityModels.models, _this.allModelsIds, undefined, aCityModels.modelSettings);
    });
    Logger.debug(this.modelsToRender, THREE_MODELS);
    var bboxCentre = turfPoint(map.getCenter().toArray());
    var mapBounds = map.getBounds();
    var mapBbox = [mapBounds.getWest(), mapBounds.getSouth(), mapBounds.getEast(), mapBounds.getNorth()];

    /**
     * use the three.js GLTF loader to add the 3D model to the three.js scene
     * @param model
     * @param modelConfig
     */
    function loadModel(model, modelConfig) {
      var modelPath = getModelPath(model, layerOptions);
      setModelOptions(modelConfig, {
        loading: true
      }, true, THREE_MODELS, map);
      var scaleMultiplier = 25.641;
      var options = {
        obj: modelPath,
        type: 'gltf',
        scale: {
          x: modelConfig.scale * scaleMultiplier,
          y: modelConfig.scale * scaleMultiplier,
          z: modelConfig.scale * scaleMultiplier
        },
        units: 'meters',
        rotation: {
          x: 0,
          y: 0,
          z: 0
        },
        anchor: 'auto'
      };
      THREE_BOX.loadObj(options, function (model) {
        setModelOptions(modelConfig, {
          loaded: true,
          loading: false,
          added: false,
          THREE_OBJECT: null,
          meshList: []
        }, true, THREE_MODELS, map);
        positionModelsWithId(model.id, THREE_MODELS, map, mapBbox, layerOptions);
        var origin = [].concat(modelConfig.center, [modelConfig.altitude]);
        model.modelId = modelConfig === null || modelConfig === void 0 ? void 0 : modelConfig.modelId;
        model.properties = modelConfig === null || modelConfig === void 0 ? void 0 : modelConfig.properties;
        model.interactive = modelConfig === null || modelConfig === void 0 ? void 0 : modelConfig.interactive;
        modelConfig.THREE_OBJECT = model;
        model.setCoords(origin);
        model.setRotation({
          x: modelConfig.rotate[0],
          y: modelConfig.rotate[1],
          z: modelConfig.rotate[2]
        });
        THREE_BOX.add(model);
        THREE_BOX.update();
      });
    }

    // function createMeshLoaderInterval(model, modelConfig, children, intervals) {
    //   let meshCount = 0;
    //   const materials = [];
    //   const materialIntervals = {};
    //   const defaultMaterial = new THREE.MeshBasicMaterial({
    //     color: getModelConfig('meshColor', model, layerOptions),
    //   });

    //   const modelId = `${model.id}_${model.index}`;
    //   const seconds = getModelConfig('meshInterval', model, layerOptions);
    //   const materialSeconds = getModelConfig(
    //     'textureInterval',
    //     model,
    //     layerOptions
    //   );

    //   const interval = setInterval(() => {
    //     meshCount = meshRenderer(
    //       children,
    //       meshCount,
    //       modelConfig,
    //       materials,
    //       defaultMaterial,
    //       map,
    //       mapBbox,
    //       layerOptions
    //     );
    //     // end the interval when all children are rendered
    //     if (meshCount === children.length) {
    //       clearInterval(intervals[modelId]);
    //       intervals[modelId] = null;

    //       let materialCount = 0;

    //       let modelConfigs = THREE_MODELS.filter(m => m.id === modelConfig.id);

    //       materialIntervals[modelId] = setInterval(() => {
    //         materialCount = materialRenderer(
    //           materials,
    //           materialCount,
    //           modelConfigs,
    //           map
    //         );
    //         if (materialCount === materials.length) {
    //           clearInterval(materialIntervals[modelId]);
    //           materialIntervals[modelId] = null;
    //         }
    //       }, materialSeconds);

    //       setModelOptions(
    //         modelConfig,
    //         { THREE_OBJECT: modelConfig.THREE_OBJECT },
    //         true,
    //         THREE_MODELS,
    //         map
    //       );
    //       positionModelsWithId(
    //         model.id,
    //         THREE_MODELS,
    //         map,
    //         mapBbox,
    //         layerOptions
    //       );
    //     }
    //   }, seconds);

    //   intervals[modelId] = interval;

    //   return interval;
    // }

    function loadOrRenderModelsWithQueue(models, cityModels) {
      Logger.debug('loadOrRenderModelsWithQueue', models, cityModels);
      var queueModels = [].concat(models);
      cityModels.forEach(function (aCityModels) {
        var _aCityModels$settings, _aCityModels$settings2, _aCityModels$settings3, _aCityModels$settings4, _aCityModels$settings5;
        queueModels.push(_extends({
          id: aCityModels.id,
          modelSettings: aCityModels.modelSettings
        }, aCityModels.settings, {
          center: ((_aCityModels$settings = aCityModels.settings) === null || _aCityModels$settings === void 0 ? void 0 : _aCityModels$settings.center) || {
            latitude: map.getCenter().lat,
            longitude: map.getCenter().lng
          },
          centerArr: [((_aCityModels$settings2 = aCityModels.settings) === null || _aCityModels$settings2 === void 0 ? void 0 : (_aCityModels$settings3 = _aCityModels$settings2.center) === null || _aCityModels$settings3 === void 0 ? void 0 : _aCityModels$settings3.longitude) || map.getCenter().lng, ((_aCityModels$settings4 = aCityModels.settings) === null || _aCityModels$settings4 === void 0 ? void 0 : (_aCityModels$settings5 = _aCityModels$settings4.center) === null || _aCityModels$settings5 === void 0 ? void 0 : _aCityModels$settings5.latitude) || map.getCenter().lat],
          type: 'city',
          models: aCityModels.models
        }));
      });
      var queue = new TinyQueue(queueModels, function (a, b) {
        var coordsA = turfPoint(Array.isArray(a.center) ? a.center : [a.center.latitude, a.center.longitude]);
        var coordsB = turfPoint(Array.isArray(b.center) ? b.center : [b.center.latitude, b.center.longitude]);
        var distA = turfDistance(coordsA, bboxCentre, {
          units: 'kilometers'
        });
        var distB = turfDistance(coordsB, bboxCentre, {
          units: 'kilometers'
        });
        if (distA < distB) {
          return -1;
        }
        if (distA > distB) {
          return 1;
        }
        return 0;
      });
      resources = {
        summaryPols: 0,
        summarySize: 0
      };

      //TODO: optimize this loop
      THREE_MODELS.forEach(function (modelConfig) {
        if (modelConfig.loaded && modelConfig.THREE_OBJECT) {
          resources.summaryPols += modelConfig.polygons || 0;
          resources.summarySize += modelConfig.size || 0;
        }
      });
      var _loop = function _loop() {
        var model = queue.pop();
        if (model.type === 'city') {
          handleCityModels(model);
        } else {
          var modelConfig = THREE_MODELS.find(function (m) {
            return m.id === model.id && m.index === model.index;
          });
          handleModel(model, modelConfig);
        }
      };
      while (queue.length) {
        _loop();
      }
    }
    function handleCityModels(aCityModels) {
      Logger.debug('Handle City Models for ', aCityModels.id);
      if (shouldRenderCityModel(aCityModels, mapBbox, map.getZoom())) {
        var modelsToRender = [];
        getFormattedModels(aCityModels.models, modelsToRender, aCityModels.modelSettings);
        if (aCityModels.modelSettings.pegmanHoverColor) {
          pegmanHoverColor = aCityModels.modelSettings.pegmanHoverColor;
        }
        Logger.debug('modelsToRender', modelsToRender);
        loadOrRenderModelsWithQueue(modelsToRender, []);
        LOADED_CITIES[aCityModels.id] = true;
      } else if (LOADED_CITIES[aCityModels.id]) {
        Logger.debug('unload city models for', aCityModels.id, THREE_MODELS);
        aCityModels.models.forEach(function (model) {
          Logger.debug('model', model);
          var modelConfigLow = THREE_MODELS.find(function (m) {
            return m.id === model.id + '_low' && m.index === 0;
          });
          var modelConfigHigh = THREE_MODELS.find(function (m) {
            return m.id === model.id + '_high' && m.index === 0;
          });
          modelConfigLow && removeModel(model.id + '_low', modelConfigLow);
          modelConfigHigh && removeModel(model.id + '_high', modelConfigHigh);
        });
        LOADED_CITIES[aCityModels.id] = false;
      }
    }
    function removeModel(modelId, modelConfig) {
      Logger.debug('remove model', modelConfig);
      createModelRemovalTimeout(modelId, modelConfig, map, THREE_MODELS, THREE_BOX);
      toggleModelsVisibility(modelConfig, false);
    }
    function handleModel(model, modelConfig) {
      if (!model || !modelConfig) {
        return;
      }
      var modelId = model.id;
      //load the model only if it is inside the current bbox and fits the current zoom level
      if (shouldLoadModel(map, model, modelConfig, THREE_MODELS) && shouldRenderModel(map, modelConfig || model, mapBbox, layerOptions)) {
        if (isIOS) {
          resources.summaryPols += modelConfig.polygons || 0;
          if (resources.summaryPols < 300000) {
            loadModel(model, modelConfig);
          }

          //}
        } else {
          if (window.THREE_BOX.renderer.info.memory.geometries < 800 && window.THREE_BOX.renderer.info.memory.textures < 600) {
            loadModel(model, modelConfig);
          }
        }
      } else if (modelConfig.THREE_OBJECT) {
        if (shouldRenderModel(map, modelConfig, mapBbox, layerOptions)) {
          // In case the model is set to be removed from the memory with a timeout and comes back
          // in view, then we need to cancel the timeout.
          clearModelRemovalTimeout(modelId, model.index);
          toggleModelsVisibility(modelConfig, true);
        } else {
          // When a model is out of view, we want to set a timeout to remove it from memory
          createModelRemovalTimeout(modelId, modelConfig, map, THREE_MODELS, THREE_BOX);
          toggleModelsVisibility(modelConfig, false);
        }
      }
    }
    function togglePOIModel(_ref) {
      var currentModelId = _ref.currentModelId,
        nextModelId = _ref.nextModelId,
        nextModelGroupIndex = _ref.nextModelGroupIndex;
      disposeModelWithId(currentModelId, map, THREE_MODELS, THREE_BOX, MODEL_IDS);
      window.THREE_BOX.renderer.clear(false, true, true);
      window.THREE_BOX.update();
      var model = (modelGroups[nextModelGroupIndex] || []).find(function (m) {
        return m.id === nextModelId;
      });
      this.modelsToRender = [];
      addNewModel(model, THREE_MODELS, MODEL_IDS, this.modelsToRender);
      map.triggerRepaint();
      loadOrRenderModelsWithQueue(this.modelsToRender, this.cityModels);
    }
    function toggleModelGroups() {
      var _this2 = this;
      disposeAllModels(map, THREE_MODELS, THREE_BOX);
      THREE_MODELS = [];
      MODEL_IDS = {};
      window.THREE_BOX.renderer.clear(false, true, true);
      window.THREE_BOX.update();
      this.activeModelGroup = this.activeModelGroup ? 0 : 1;
      models = modelGroups[this.activeModelGroup];
      this.modelsToRender = [];
      models.forEach(function (model) {
        addNewModel(model, THREE_MODELS, MODEL_IDS, _this2.modelsToRender);
      });
      map.triggerRepaint();
      loadOrRenderModelsWithQueue(this.modelsToRender, this.cityModels);
    }
    function toggleLayerVisibility(visible) {
      var _this3 = this;
      if (!visible) {
        //TDOD: clear all intervals

        disposeAllModels(map, THREE_MODELS, THREE_BOX);
        THREE_MODELS = [];
        MODEL_IDS = {};
        window.THREE_BOX.renderer.clear(false, true, true);
        window.THREE_BOX.update();
        map.triggerRepaint();
      } else {
        if (layerOptions.globalModelToggle) {
          models = modelGroups[this.activeModelGroup || 0];
        } else {
          models = commonModels.concat(modelGroups[this.activeModelGroup || 0]);
        }
        this.modelsToRender = [];
        this.allModelsIds = {};
        models.forEach(function (model) {
          // eslint-disable-next-line no-prototype-builtins
          if (_this3.allModelsIds.hasOwnProperty(model.id)) {
            _this3.allModelsIds[model.id]++;
            model.id = model.id + '-' + _this3.allModelsIds[model.id];
          } else {
            _this3.allModelsIds[model.id] = 0;
          }
          addNewModel(model, THREE_MODELS, MODEL_IDS, _this3.modelsToRender);
        });
        map.triggerRepaint();
        loadOrRenderModelsWithQueue(this.modelsToRender, this.cityModels);
      }
      this.on = visible;
    }
    document.addEventListener('mpr-switch-3d', function () {
      toggleModelGroups.call(_this);
    });
    document.addEventListener('mpr-switch-poi-3d', function (event) {
      togglePOIModel.call(_this, event.detail);
    });
    document.addEventListener('mpr-toggle-3d-layer', function (event) {
      toggleLayerVisibility.call(_this, event.detail.visible);
    });

    //loadOrRenderModelsWithQueue(modelsToRender);

    map.on('moveend', function () {
      //update saved map bounds
      bboxCentre = turfPoint(map.getCenter().toArray());
      mapBounds = map.getBounds();
      mapBbox = [mapBounds.getWest(), mapBounds.getSouth(), mapBounds.getEast(), mapBounds.getNorth()];
      if (!_this.on) {
        return;
      }
      _this.modelsToRender = [];
      getFormattedModels(models, _this.modelsToRender);
      loadOrRenderModelsWithQueue(_this.modelsToRender, _this.cityModels);
    });

    // let highlightedModelId;
    // const highlighted = [];

    map.on('mousemove', function (e) {
      var mapPadding = map.getPadding();
      var mouseCoords = {
        x: e.point.x - mapPadding.left / 2 + mapPadding.right / 2,
        y: e.point.y - mapPadding.top / 2 + mapPadding.bottom / 2
      };
      var _findVisitPoi = findVisitPoi(mouseCoords, THREE_BOX),
        interactedObject = _findVisitPoi.interactedObject;
      if (pegman && !(interactedObject === null || interactedObject === void 0 ? void 0 : interactedObject.parent.parent.parent.modelId)) {
        map.getCanvasContainer().style.cursor = 'grab';
        pegman.children[0].children[0].children[0].material.color.setHex(0xffffff);
        THREE_BOX.update();
        map.triggerRepaint();
        pegman = null;
      } else if (pegman && !(interactedObject === null || interactedObject === void 0 ? void 0 : interactedObject.parent.parent.parent.properties)) {
        map.getCanvasContainer().style.cursor = 'grab';
        pegman.children[0].children[0].children[0].material.color.setHex(0xffffff);
        THREE_BOX.update();
        map.triggerRepaint();
        pegman = null;
      } else if (interactedObject && (interactedObject === null || interactedObject === void 0 ? void 0 : interactedObject.parent.parent.parent.modelId) && (interactedObject === null || interactedObject === void 0 ? void 0 : interactedObject.parent.parent.parent.interactive) && (interactedObject === null || interactedObject === void 0 ? void 0 : interactedObject.parent.parent.parent.interactive.hover) == true && pegman == null) {
        map.getCanvasContainer().style.cursor = 'pointer';
        var scene = interactedObject.parent.parent.parent;
        pegman = scene.getObjectByProperty('modelId', interactedObject.parent.parent.parent.modelId);
        pegman.children[0].children[0].children[0].material = pegman.children[0].children[0].children[0].material.clone();
        pegman.children[0].children[0].children[0].material.color.setHex('0x' + pegmanHoverColor);
        map.triggerRepaint();
        THREE_BOX.update();
      }
    });
    map.on('mousedown', function (e) {
      // alert(
      //   window.THREE_BOX.renderer.info.memory.geometries +
      //     ' | ' +
      //     window.THREE_BOX.renderer.info.memory.textures
      // );
      // clearModelHighlights(highlighted);

      var mapPadding = map.getPadding();
      var mouseCoords = {
        x: e.point.x - mapPadding.left / 2 + mapPadding.right / 2,
        y: e.point.y - mapPadding.top / 2 + mapPadding.bottom / 2
      };
      var _findVisitPoi2 = findVisitPoi(mouseCoords, THREE_BOX),
        interactedObject = _findVisitPoi2.interactedObject,
        intersectionExists = _findVisitPoi2.intersectionExists;
      var show = true;
      if (intersectionExists) {
        var IntObjectProperties;
        // if (isIOS) {
        //   IntObjectProperties = interactedObject.parent.parent.parent.properties;
        //   if (resources.summaryPols > 400000) {
        //     show = false;
        //   }
        // } else {
        //   IntObjectProperties = interactedObject.parent.parent.parent.properties;
        // }
        IntObjectProperties = interactedObject.parent.parent.parent.properties;
        if (interactedObject && IntObjectProperties && show && typeof IntObjectProperties.URL == 'string') {
          var url = IntObjectProperties.URL;
          var visitEvent = new CustomEvent('select-pegman', {
            detail: {
              url: url
            }
          });
          document.dispatchEvent(visitEvent);
        }
      }
      var foundModelId;
      if (intersectionExists) {
        // if (isIOS) {
        //   foundModelId = interactedObject.parent.parent.modelId;
        // } else {
        //   foundModelId = interactedObject.parent.modelId;
        // }
        foundModelId = interactedObject.parent.parent.parent.modelId;
        var modelConfig = THREE_MODELS.find(function (m) {
          return m.modelId === foundModelId;
        });
        if (modelConfig && (modelConfig.interactive === true || typeof modelConfig.interactive === 'object' && modelConfig.interactive.select) && interactedObject) {
          // highlightModelObject(interactedObject, highlighted);
          layerOptions.methods && layerOptions.methods.selectPOIWithModelId && layerOptions.methods.selectPOIWithModelId(foundModelId);
        }
      }

      // on state change, fire a repaint
      // if (highlightedModelId !== foundModelId) {
      //   highlightedModelId = foundModelId;
      //   THREE_BOX.repaint();
      // }
    });

    LOADED_CUSTOM_LAYERS.push(layerId);
  },
  render: function render() {
    window.THREE_BOX.renderer.clear(false, true, true);
    window.THREE_BOX.update();
  },
  onRemove: function onRemove(map) {
    disposeAllModels(map, THREE_MODELS, this.THREE_BOX);
    THREE_MODELS = [];
    MODEL_IDS = {};
    this.THREE_BOX.renderer.clear(false, true, true);
    this.THREE_BOX.update();
    map.triggerRepaint();
    var index = LOADED_CUSTOM_LAYERS.indexOf(this.layerId);
    if (index !== -1) {
      LOADED_CUSTOM_LAYERS.splice(index, 1);
    }
  }
};