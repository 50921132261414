import React, {
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import {AppContext} from '../../context';
import PropTypes from 'prop-types';
import Map from './Map/Map';
import SideBar from './Sidebar/SideBar';
import './DesktopLayout.scss';
import ListLoader from '../../components/loaders/ListLoader/ListLoader';
import {getDomain} from '../../utils/envUtils';
import AppInfoModal from '../../components/modals/AppInfoModal/AppInfoModal';
import Tutorial from './Tutorial/Tutorial';
import {getHash} from '../../utils/urlUtils';
import CssFilterConverter from 'css-filter-converter';

function DesktopLayout({loading, project, basemaps,onToggle}) {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [openAppInfoModal, setAppInfoModal] = useState(false);
    const [openedControl, setOpenedControl] = useState('');
    const {streetViewModal} = useContext(AppContext);
    const [openTutorial, setOpenTutorial] = useState(false);
    const handleViewSidebar = useCallback(() => {
        if (streetViewModal.open) {
            streetViewModal.handleClose();
        }
        setIsSidebarOpen(!isSidebarOpen);

    }, [isSidebarOpen, streetViewModal]);
    const urlColorMode = getHash('color') && `#${getHash('color')}`;

    const handleModalClose = () => {
        setAppInfoModal(false);
    };

    const handleOpenTutorial = (isOpen) => {
        setOpenTutorial(isOpen);
    };

    useEffect(() => {
        const search = new URLSearchParams(
            window.location.hash.replace('#', '')
        );
        const {filters} = Object.fromEntries(
            search.entries()
        );
        if (!filters || Object.keys(JSON.parse(filters)).length === 0) {
            onToggle('type', 'Gare');
        }
    }, [onToggle]);

    useEffect(() => {
        if (project) {
            const d = getDomain();
            const isFirstLaunch = !localStorage.getItem(`${d}-mpr-visited`);

            if (
                !!project.configs.appInfoModalEnabled &&
                project.configs?.appInfoModalEmergence === 'onEveryLoad'
            ) {
                setAppInfoModal(true);
            }

            if (isFirstLaunch) {
                localStorage.setItem(`${d}-mpr-visited`, '1');
                setOpenTutorial(!!project.configs.tutorialEnabledOnFirstLoad);

                if (
                    !!project.configs.appInfoModalEnabled &&
                    (project.configs?.appInfoModalEmergence === 'onFirstLoad' ||
                        !project.configs?.appInfoModalEmergence)
                ) {
                    setAppInfoModal(true);
                }
            } else {
                setOpenTutorial(false);
            }
        }
    }, [project]);

    useEffect(() => {
        if (streetViewModal.open) {
            setIsSidebarOpen(false);
        }
    }, [streetViewModal]);


    useEffect(() => {
        if (project) {
            const {primaryColor} = project.configs;
            const result = CssFilterConverter.hexToFilter(urlColorMode || primaryColor);
            const root = document.documentElement;
            root?.style.setProperty(
                '--btn-background-color', urlColorMode || primaryColor
            );
            root?.style.setProperty(
                '--filter-svg-color', result?.color
            );
        }

    }, [project, urlColorMode]);

    if (loading) {
        return <ListLoader/>;
    }

    return (
        <div className="container-fluid h-100">
            <section className="row justify-content-center h-100">
                <div className="wrapper p-0">
                    <SideBar
                        project={project}
                        isSidebarOpen={isSidebarOpen}
                        toggleMenu={handleViewSidebar}
                        loading={loading}
                        openedControl={openedControl}
                        setOpenedControl={setOpenedControl}
                    />
                    <Map
                        projectConfigs={project.configs}
                        basemaps={basemaps}
                        isSidebarOpen={isSidebarOpen}
                        loading={loading}
                        openedControl={openedControl}
                        setOpenedControl={setOpenedControl}
                        setSideBarOpen={setIsSidebarOpen}
                        position="full"
                    />
                </div>
            </section>
            {openAppInfoModal && (
                <AppInfoModal
                    open={openAppInfoModal}
                    onClose={handleModalClose}
                    project={project}
                    appInfoModal={project.configs.appInfoModal}
                />
            )}
            {openTutorial && (
                <Tutorial handleOpenTutorial={handleOpenTutorial}/>
            )}
        </div>
    );
}

DesktopLayout.propTypes = {
    loading: PropTypes.bool,
    project: PropTypes.object,
    basemaps: PropTypes.array,
    onToggle:PropTypes.func
};

export default DesktopLayout;
