import React, { useContext, useEffect, useRef, useState } from 'react';
import './SideBar.scss';
import PropTypes from 'prop-types';
import {
    Filters as CoreFilters,
    Items,
    SelectedItem,
    useMap,
} from '@mappr/react-lib';
import { useParams } from 'react-router';
import LineView from './POI/LineView';
import { JourneyList } from './Journey/JourneyList';
import Poi from './POI/Poi';
import NotFound from '../../../components/NotFound/NotFound';
import Calendar from './Calendar/Calendar';
import Tabs from './Tabs/Tabs';
import Layer from './Layer/Layer';
import { AppContext } from '../../../context';
import {getHash} from '../../../utils/urlUtils';

const SideBar = ({
    isSidebarOpen,
    toggleMenu,
    project,
    openedControl,
    setOpenedControl,
}) => {

    const { mode } = useParams();
    const { layout } = useContext(AppContext);
    const [lines, setLines] = useState(undefined);
    const { map } = useMap();
    // const [calendar, setCalendar] = useState(undefined);
    const { configs } = project;
    const { primaryColor } = configs;
    const sidebarRef = useRef(null);
    const search = new URLSearchParams(window.location.hash.replace('#', ''));
    const { filters, selectedItem, journey } = Object.fromEntries(
        search.entries()
    );
    const urlColorMode = getHash('color') && `#${getHash('color')}`;
    useEffect(() => {
        if (filters) {
            setLines(JSON.parse(filters).lines);
            // setCalendar(JSON.parse(filters).calendar);
        } else {
            setLines('');
            // setCalendar('');
        }
    }, [filters]);

    useEffect(() => {
        if (map && project?.configs?.sidebarCollapse) {
            const padding = ['sidebarRight', 'headerRight'].includes(layout)
                ? {
                      right: isSidebarOpen
                          ? sidebarRef?.current?.offsetWidth
                          : 0,
                  }
                : {
                      left: isSidebarOpen
                          ? sidebarRef?.current?.offsetWidth
                          : 0,
                  };
            window.sidebarOffsetX = padding.left/2;
            setTimeout(() => {
                map.easeTo({
                    padding: padding,
                    duration: 500,
                });
            }, 100);
        }
    }, [
        isSidebarOpen,
        sidebarRef,
        map,
        project?.configs?.sidebarCollapse,
        layout,
    ]);

    const sidebarContent = (items, selectItem, deselect, item, error) => {
        if (
            (!selectedItem && !journey && !openedControl && !lines) ||
            (!journey && !lines && item && item?.properties.type !== 'Gare')
        ) {
            return (
                <Tabs
                    isSidebarOpen={isSidebarOpen}
                    toggleMenu={toggleMenu}
                    project={project}
                />
            );
        }
        if (
            selectedItem &&
            !journey &&
            item &&
            item?.properties.type === 'Gare'
        ) {
            return (
                <Poi
                    project={project}
                    toggleMenu={toggleMenu}
                    primaryColor={primaryColor}
                    isSidebarOpen={isSidebarOpen}
                    deselect={deselect}
                    item={item}
                    error={error}
                />
            );
        }
        if (!selectedItem) {
            if (openedControl === 'calendar') {
                return (
                    <Calendar
                        project={project}
                        toggleMenu={toggleMenu}
                        primaryColor={primaryColor}
                        isSidebarOpen={isSidebarOpen}
                        selectedItem={selectedItem}
                        lines={lines}
                    />
                );
            } else if (openedControl === 'layers') {
                return (
                    <Layer
                        project={project}
                        toggleMenu={toggleMenu}
                        primaryColor={primaryColor}
                        isSidebarOpen={isSidebarOpen}
                        selectedItem={selectedItem}
                        lines={lines}
                        setOpenedControl={setOpenedControl}
                    />
                );
            }
        }

        if (mode === 'line') {
            if ((lines && !selectedItem) || (selectedItem && item && item?.properties.type !== 'Gare')) {
                return (
                    <CoreFilters>
                        {({ filters, loading }, { onToggle }) => {
                            return (
                                <LineView
                                    onToggle={onToggle}
                                    project={project}
                                    item={items}
                                    fiitemslters={filters}
                                    toggleMenu={toggleMenu}
                                    primaryColor={primaryColor}
                                    isSidebarOpen={isSidebarOpen}
                                    selectItem={selectItem}
                                    line={lines[0]}
                                    loading={loading}
                                />
                            );
                        }}
                    </CoreFilters>
                );
            } else if (journey) {
                return <JourneyList project={project} />;
            } else {
                return null;
            }
        } else if (mode === 'simulator') {
            if (journey) {
                return <JourneyList project={project} />;
            } else {
                return <NotFound />;
            }
        } else {
            return <NotFound />;
        }
    };

    return (
        <Items>
            {({ items }, { selectItem }) => {
                return (
                    <div
                        id="sidebar"
                        ref={sidebarRef}
                        style={{ background: urlColorMode || primaryColor }}
                        className={`sidebar px-1 ps-0 d-flex flex-column ${
                            isSidebarOpen ? '' : 'sidebar--expanded'
                        }`}
                    >
                        <SelectedItem>
                            {({ error, item }, { deselect }) => {
                                const itemsList = items.filter(
                                    (_) => _.properties.type === 'Gare'
                                );
                                return sidebarContent(
                                    itemsList,
                                    selectItem,
                                    deselect,
                                    item,
                                    error
                                );
                            }}
                        </SelectedItem>
                    </div>
                );
            }}
        </Items>
    );
};
SideBar.propTypes = {
    isSidebarOpen: PropTypes.bool,
    openedControl: PropTypes.string,
    project: PropTypes.object,
    sidebarRef: PropTypes.object,
    toggleMenu: PropTypes.func,
    setOpenedControl: PropTypes.func,
};
export default SideBar;
