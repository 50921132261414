import React from 'react';
import { Col, Container, Image, Row, Button } from 'react-bootstrap';
import metro from '../../../assets/icons/picto_svg/Métro.svg';
import rer from '../../../assets/icons/picto_svg/RER.svg';
import tram from '../../../assets/icons/picto_svg/Tramway.svg';
import train from '../../../assets/icons/picto_svg/Train.svg';
import SimulatorSearch from '../../SimulatorSearch/SimulatorSearch';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

export const POIPage = ({ item, project }) => {
    const getPoiUpperTitle = (item) => {
        const upperTitle = item.properties['upperTitle'];
        return upperTitle ? <p className={'subtitle m-0 lh-sm'}>{upperTitle}</p> : null;
    };
    const getPoiSubtitles = (item) => {
        const subtitle = project.configs.poiSubtitleFields
            .map((field) => {
                return item.properties[field.fieldName];
            })
            .join(',');
        return subtitle ? <p className={'subtitle m-0'}>{subtitle}</p> : null;
    };
    return (
        <main>
            <Container className="sidebar-page text-white">
                <Row className="mb-3">
                    <Col>
                        <div className="sidebar-page text-white">
                            <div className="title mt-0">
                                {getPoiUpperTitle(item)}
                                {item.properties.name}
                                {getPoiSubtitles(item)}
                            </div>
                            <div className="description">
                                {ReactHtmlParser(item.properties.description)}
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="mb-2 public-transport">
                    {item.properties['line-metro'].length > 0 && (
                        <div className="me-3 mb-2">
                            <Image src={metro} alt="" />
                            {item.properties['line-metro'].map(
                                (item, index) => (
                                    <Image
                                        key={'line-metro-' + index}
                                        src={require('../../../assets/icons/picto_svg/' +
                                            item +
                                            '.svg')}
                                        alt=""
                                    />
                                )
                            )}
                        </div>
                    )}
                    {item.properties['line-rer'].length > 0 && (
                        <div className="me-3 mb-2">
                            <Image src={rer} alt="" />
                            {item.properties['line-rer'].map((item, index) => {
                                return (
                                    <Image
                                        key={'line-rer-' + index}
                                        src={require('../../../assets/icons/picto_svg/' +
                                            item +
                                            '.svg')}
                                        alt=""
                                    />
                                );
                            })}
                        </div>
                    )}
                    {item.properties['line-tram'].length > 0 && (
                        <div className="me-3 mb-2">
                            <Image src={tram} alt="" />
                            {item.properties['line-tram'].map((item, index) => (
                                <Image
                                    key={'line-tram-' + index}
                                    src={require('../../../assets/icons/picto_svg/' +
                                        item +
                                        '.svg')}
                                    alt=""
                                />
                            ))}
                        </div>
                    )}
                    {item.properties['line-transilien'].length > 0 && (
                        <div className="me-3 mb-2">
                            <Image src={train} alt="" />
                            {item.properties['line-transilien'].map(
                                (item, index) => (
                                    <Image
                                        key={'line-transilien-' + index}
                                        src={require('../../../assets/icons/picto_svg/' +
                                            item +
                                            '.svg')}
                                        alt=""
                                    />
                                )
                            )}
                        </div>
                    )}
                </div>
                {item.properties['url-fiche-gare'] && (
                    <Row className="mb-5">
                        <Col>
                            <a
                                href={item.properties['url-fiche-gare']}
                                target={'_blank'}
                                rel="noreferrer"
                            >
                                <Button className="nav-link w-100   cursor-pointer mt-4 btn-calculate">
                                    Voir la page gare
                                </Button>
                            </a>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col>
                        <p className="title poi-simulator-title mb-4">
                            Itinéraires
                        </p>
                        <div>
                            <SimulatorSearch
                                from={{
                                    name: item.properties.name,
                                    coord: {
                                        lat: item.geometry.coordinates[1],
                                        lon: item.geometry.coordinates[0],
                                    },
                                }}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </main>
    );
};
POIPage.propTypes = {
    item: PropTypes.object,
    project: PropTypes.object,
};
