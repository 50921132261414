import React from 'react';
import { Layers } from '@mappr/react-lib';
import './LayersMenu.scss';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function LayersMenu({ openedControls, controlsToggle }) {
    const { t } = useTranslation();
    return (
        <div className="layers-box">
            {openedControls === 'layers' && (
                <div className="layers-menu  max-z-index">
                    <Layers group={'secondary'}>
                        {({ layers, activeLayers }, { toggleLayer }) => {
                            return layers.map((section) => {
                                if (section.id === '3' || section.id === '4')
                                    return null;
                                let sectionHeader = null;
                                if (section.id) {
                                    sectionHeader = (
                                        <div
                                            key={`section_${section.id}`}
                                            className="layers-menu__section mt-4"
                                        >
                                            {t(
                                                `project:map.layers.sections.section_${section.id}`
                                            )}
                                        </div>
                                    );
                                }

                                let sectionLayers = section.secondaryLayers.map(
                                    (aLayer) => {
                                        const inputId = `layer-${aLayer.name}-check`;
                                        return aLayer.canBeToggled ? (
                                            <div
                                                key={aLayer.name}
                                                className="layers-menu__layer"
                                                onClick={() => {
                                                    toggleLayer(
                                                        aLayer.name,
                                                        'secondary'
                                                    );
                                                }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    name={inputId}
                                                    id={inputId}
                                                    checked={activeLayers.includes(
                                                        aLayer.name
                                                    )}
                                                    readOnly
                                                />
                                                <label htmlFor={inputId}>
                                                    {t(
                                                        `project:map.layers.${aLayer.name}`
                                                    )}
                                                </label>
                                            </div>
                                        ) : null;
                                    }
                                );

                                return [sectionHeader, ...sectionLayers];
                            });
                        }}
                    </Layers>
                    <div
                        className="layers-menu__close"
                        onClick={() => {
                            controlsToggle('');
                        }}
                    />
                </div>
            )}
        </div>
    );
}

LayersMenu.propTypes = {
    openedControls: PropTypes.string,
    controlsToggle: PropTypes.func,
    mode: PropTypes.string,
};
export default LayersMenu;
