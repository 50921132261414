import React, { useCallback } from 'react';
import Header from '../../Header/Header';
import { Col, Row } from 'react-bootstrap';
import Footer from '../../Footer/Footer';
import './Calendar.scss';
import { Filters as CoreFilters, Layers } from '@mappr/react-lib';
import SidebarToggleButton from '../../../../components/SidebarToggleButton/SidebarToggleButton';
import PropTypes from 'prop-types';

const Calendar = ({ project, toggleMenu, primaryColor, isSidebarOpen }) => {
    const onChangeValue = (toggleLayer, onToggle, selectedValues) => {
        toggleLayer('background-sgp-full', 'basemap');
        if (selectedValues?.calendar) {
            onToggle('calendar', selectedValues?.calendar[0]);
        }
    };
    const handleDeselect = useCallback((removeAllFilters, toggleLayer) => {
        removeAllFilters();
        onChangeValue(toggleLayer);
    }, []);
    return (
        <Layers group={'basemap'}>
            {/* eslint-disable-next-line no-empty-pattern */}
            {({}, { toggleLayer }) => {
                return isSidebarOpen ? (
                    <>
                        <CoreFilters>
                            {/* eslint-disable-next-line no-empty-pattern */}
                            {({}, { removeAllFilters }) => {
                                return (
                                    <Header
                                        projectConfigs={project.configs}
                                        backUrl={'/'}
                                        isRefreshThePage={true}
                                        deselect={() =>
                                            handleDeselect(
                                                removeAllFilters,
                                                toggleLayer
                                            )
                                        }
                                    />
                                );
                            }}
                        </CoreFilters>
                        <main>
                            <div className="sidebar-page text-white">
                                <Row className="mb-3">
                                    <Col>
                                        <div className="sidebar-page text-white calendar-sidebar">
                                            <p className="title mt-0">
                                                Découvrez les mises en service
                                            </p>
                                            <div className="description">
                                                Le calendrier correspond la
                                                feuille de route de la Société
                                                des grands projets validée le 13
                                                juillet 2021. Ces informations
                                                sont données à titre indicatif.
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </main>
                        <Footer
                            toggleMenu={toggleMenu}
                            primaryColor={primaryColor}
                        />
                    </>
                ) : (
                    <SidebarToggleButton
                        toggleMenu={toggleMenu}
                        isSidebarOpen={isSidebarOpen}
                    />
                );
            }}
        </Layers>
    );
};

Calendar.propTypes = {
  project: PropTypes.object,
  toggleMenu: PropTypes.func,
  primaryColor: PropTypes.string,
  isSidebarOpen: PropTypes.bool,
};
export default Calendar;
