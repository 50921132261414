import React, { useCallback, useEffect, useState } from 'react';
import { Col, FormLabel, Image, InputGroup, Row } from 'react-bootstrap';
import Downshift from 'downshift';
import PropTypes from 'prop-types';
import departure from '../../assets/icons/departure.svg';
import arrival from '../../assets/icons/arrival.svg';
import metro from '../../assets/icons/pubic_transport.svg';
import address from '../../assets/icons/address.svg';
import { useTranslation } from 'react-i18next';

const AutocompleteInput = ({
    onUpdate,
    onSelect,
    suggestions,
    setItem,
    item,
    point,
    placeholder,
    onFiltersExpandToggle,
    label,
    loading,
}) => {
    const [inputValue, setInputValue] = useState(item?.name || '');
    const [timeoutId, setTimeoutId] = useState(undefined);
    useEffect(() => {
        if (item) {
            setInputValue(item.name);
        }
    }, [item]);
    const { t } = useTranslation();
    const handleSearchInputChange = useCallback(
        (inputValue) => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            setInputValue(inputValue);
            const str = inputValue.split(' ').filter((s) => s.length >= 1);

            setTimeoutId(
                setTimeout(() => {
                    onUpdate(str.length ? str.join(' ') : '');
                }, 500)
            );
        },
        [onUpdate, timeoutId]
    );

    const stateReducer = useCallback((state, changes, suggestions) => {
        switch (changes.type) {
            case Downshift.stateChangeTypes.keyDownArrowDown:
            case Downshift.stateChangeTypes.keyDownArrowUp: {
                const selectedItem = suggestions[changes.highlightedIndex];
                if (selectedItem) {
                    setInputValue(selectedItem.name);
                }
                return {
                    ...changes,
                    inputValue: selectedItem
                        ? selectedItem.name
                        : state.inputValue,
                };
            }
            default:
                return changes;
        }
    }, []);

    const handleFocus = useCallback(() => {
        onFiltersExpandToggle && onFiltersExpandToggle(false);
    }, [onFiltersExpandToggle]);
    const handleBlur = useCallback(() => {
        onFiltersExpandToggle && onFiltersExpandToggle(true);
    }, [onFiltersExpandToggle]);

    const handleChange = useCallback(
        (selected) => {
            setItem(selected);
        },
        [setItem]
    );

    const handleStateReducer = useCallback(
        (state, changes) =>
            stateReducer(state, changes, ...suggestions, onSelect),
        [onSelect, stateReducer, suggestions]
    );

    const handleItemToString = useCallback(
        (item) => (item ? item.name : ''),
        []
    );

    return (
        <Downshift
            inputValue={inputValue}
            onInputValueChange={handleSearchInputChange}
            onChange={handleChange}
            initialSelectedItem={inputValue}
            itemToString={handleItemToString}
            stateReducer={handleStateReducer}
        >
            {({
                getInputProps,
                getItemProps,
                getMenuProps,
                isOpen,
                inputValue,
                selectedItem,
                clearSelection,
            }) => {
                return (
                    <div className={'position-relative'}>
                        <FormLabel className={'text-uppercase'}>
                            {label}
                        </FormLabel>
                        <InputGroup
                            className={
                                isOpen &&
                                inputValue.length >= 2 &&
                                suggestions.length > 0
                                    ? 'active'
                                    : selectedItem
                                    ? 'chosen'
                                    : item?.name !== inputValue &&
                                      !selectedItem &&
                                      !suggestions.length &&
                                      inputValue.length > 1 &&
                                      !loading
                                    ? 'error-input'
                                    : 'inactive'
                            }
                        >
                            <div className="input-div">
                                <InputGroup.Text
                                    id="basic-addon1"
                                    className="bg-transparent border-end-0 pe-0"
                                >
                                    <img
                                        src={
                                            point === 'start'
                                                ? departure
                                                : arrival
                                        }
                                        alt=""
                                    />
                                </InputGroup.Text>
                                <input
                                    className={
                                        ' bg-transparent border-start-0  ps-2 pe-3'
                                    }
                                    placeholder={placeholder}
                                    {...getInputProps({
                                        onChange: (e) => {
                                            if (e.target.value === '') {
                                                clearSelection();
                                            }
                                        },
                                    })}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                />
                            </div>
                        </InputGroup>
                        {item?.name !== inputValue &&
                            !selectedItem &&
                            !suggestions.length &&
                            inputValue.length > 1 &&
                            !loading && (
                                <div className="error-text ps-3 pe-3 mt-1">
                                    {' '}
                                    {t('simulator.error')}
                                </div>
                            )}
                        {isOpen &&
                        inputValue.length >= 2 &&
                        suggestions.length > 0 ? (
                            <>
                                <ul
                                    {...getMenuProps()}
                                    className="custom-autocomplete list-unstyled position-absolute w-100"
                                >
                                    {isOpen ? (
                                        <>
                                            {suggestions.map((item, index) => (
                                                <li
                                                    key={'from-' + item.name}
                                                    {...getItemProps({
                                                        index:
                                                            index +
                                                            '-' +
                                                            item.name,
                                                        item,
                                                        style: {
                                                            cursor: 'pointer',
                                                        },
                                                    })}
                                                >
                                                    <Row>
                                                        <Col
                                                            md={2}
                                                            sm={2}
                                                            xs={2}
                                                            className="align-items-center col"
                                                        >
                                                            <Image
                                                                src={
                                                                    item?.embedded_type ===
                                                                    'stop_area'
                                                                        ? metro
                                                                        : address
                                                                }
                                                                alt=""
                                                            />
                                                        </Col>
                                                        <Col
                                                            md={10}
                                                            sm={10}
                                                            xs={10}
                                                            className="align-items-center col"
                                                        >
                                                            {item.name}
                                                        </Col>
                                                    </Row>
                                                </li>
                                            ))}
                                        </>
                                    ) : null}
                                </ul>
                            </>
                        ) : null}
                    </div>
                );
            }}
        </Downshift>
    );
};

AutocompleteInput.propTypes = {
    onUpdate: PropTypes.func,
    onSelect: PropTypes.func,
    suggestions: PropTypes.array,
    setItem: PropTypes.func,
    item: PropTypes.object,
    point: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    onFiltersExpandToggle: PropTypes.func,
    loading: PropTypes.bool,
};
export default AutocompleteInput;
