import React from 'react';
import { Filters as CoreFilters, Items } from '@mappr/react-lib';
import { useParams } from 'react-router';
import LineView from './POI/LineView';
import { JourneyList } from './Journey/JourneyList';
import Poi from './POI/Poi';
import NotFound from '../../../components/NotFound/NotFound';
import Calendar from './Calendar/Calendar';
import PropTypes from 'prop-types';
import Tabs from './Tabs/Tabs';
import LegalInformation from '../IlegalInformation/LegalInformation';
import {getHash} from '../../../utils/urlUtils';

const SideBar = (props) => {
    let lines;
    let calendar;
    const { mode } = useParams();
    const { open, project, openedControl, setOpenedControl } = props;
    const { primaryColor } = project.configs;
    const search = new URLSearchParams(window.location.hash.replace('#',''));
    const { filters, selectedItem, journey } = Object.fromEntries(
        search.entries()
    );
    const urlColorMode = getHash('color') && `#${getHash('color')}`;
    if (filters) {
        lines = JSON.parse(filters).lines;
        calendar = JSON.parse(filters).calendar;
    }

    const sidebarContent = (items, selectItem, loading) => {
        if (
            (mode === 'line' &&
                !selectedItem &&
                !journey &&
                (!filters || !lines)) ||
            (mode === 'simulator' && !journey)
        ) {
            return (
                <Tabs
                    loading={loading}
                    open={open}
                    project={project}
                    primaryColor={urlColorMode || primaryColor}
                    openedControl={openedControl}
                    setOpenedControl={setOpenedControl}
                />
            );
        }
        if (calendar && !selectedItem) {
            return (
                <Calendar
                    project={project}
                    primaryColor={urlColorMode || primaryColor}
                    open={open}
                />
            );
        }
        if (selectedItem && !journey && mode !== 'simulator') {
            return (
                <Poi
                    project={project}
                    primaryColor={urlColorMode || primaryColor}
                    open={open}
                />
            );
        }
        if (mode === 'line') {
            if (lines && !selectedItem) {
                return (
                    <CoreFilters>
                        {({ filters, loading }, { onToggle }) => {
                            return (
                                <LineView
                                    onToggle={onToggle}
                                    project={project}
                                    item={items}
                                    fiitemslters={filters}
                                    primaryColor={urlColorMode || primaryColor}
                                    open={open}
                                    selectItem={selectItem}
                                    line={lines[0]}
                                    loading={loading}
                                />
                            );
                        }}
                    </CoreFilters>
                );
            } else if (journey) {
                return <JourneyList project={project} />;
            }
        } else if (mode === 'simulator') {
            if (journey) {
                return <JourneyList project={project} />;
            } else {
                return <NotFound />;
            }
        } else {
            return <NotFound />;
        }
    };

    return (
        <Items>
            {({ items, loading }, { selectItem }) => {
                return (
                    <>
                        <div className={`${!open ? 'active' : null}`} style={{width:'100vw'}}>
                            {sidebarContent(items, selectItem, loading)}
                        </div>
                        {!journey && (
                            <LegalInformation
                                primaryColor={urlColorMode || project.configs.primaryColor}
                            />
                        )}
                    </>
                );
            }}
        </Items>
    );
};
SideBar.propTypes = {
    open: PropTypes.bool,
    project: PropTypes.object,
    openedControl: PropTypes.string,
    controlsToggle: PropTypes.string,
    setOpenedControl: PropTypes.func,
};
export default SideBar;
