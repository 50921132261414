import React, {useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import './Calendar.scss';
import {Filters as CoreFilters, Layers, SelectedItem} from '@mappr/react-lib';
import PropTypes from 'prop-types';
import {CalendarItems} from './CalendarItems';
import {AppContext} from '../../context';
import {getHash} from '../../utils/urlUtils';
import CssFilterConverter from 'css-filter-converter';

export default function Calendar({
                                     openedControls,
                                     controlsToggle,
                                     map,
                                     setMaxZoomBackup,
                                     maxZoomBackup,
                                 }) {
    const {t} = useTranslation();
    const {configModal, projectConfigs} = useContext(AppContext);
    const activeBasemap = getHash('basemap');
    const urlColorMode = getHash('color') && `#${getHash('color')}`;

    useEffect(() => {
        if (projectConfigs) {
            const {primaryColor} = projectConfigs;
            const root = document.documentElement;
            const result = CssFilterConverter.hexToFilter(urlColorMode || primaryColor);
            root?.style.setProperty(
                '--primary-color', urlColorMode || primaryColor
            );
            root?.style.setProperty(
                '--filter-svg-color', result?.color
            );
        }
    }, [projectConfigs, urlColorMode]);

    const handleCalendarClick = (removeAllFilters, deselect, toggleLayer) => {
        if (
            openedControls === 'calendar' &&
            activeBasemap !== 'background-sgp-full'
        ) {
            configModal.handleSave('background-sgp-full');
            removeAllFilters();
            deselect();
            toggleLayer('background-sgp-full', 'basemap');
            location.href = '/';
        }

        if (openedControls === 'calendar') {
            controlsToggle('');
            map?.setMaxZoom(maxZoomBackup);
        } else {
            controlsToggle('calendar');
            setMaxZoomBackup(map?.getMaxZoom());
            map?.setMaxZoom(10);
        }
    };
    return (
        <div>
            <CoreFilters>
                {({selectedValues}, {onToggle, removeAllFilters}) => (
                    <Layers group={'basemap'}>
                        {({layers, activeLayers}, {toggleLayer}) => {
                            return (
                                <SelectedItem>
                                    {(
                                        // eslint-disable-next-line no-empty-pattern
                                        {},
                                        {deselect}
                                    ) => (
                                        <>
                                            <div
                                                className={`calendar-btn ${
                                                    openedControls ===
                                                    'calendar' && 'active'
                                                }`}
                                                onClick={() => {
                                                    handleCalendarClick(
                                                        removeAllFilters,
                                                        deselect,
                                                        toggleLayer
                                                    );
                                                }}
                                            >
                                                <span/>
                                                <p>{t('calendar.title')}</p>
                                            </div>

                                            <CalendarItems
                                                openedControls={openedControls}
                                                toggleLayer={toggleLayer}
                                                layers={layers}
                                                activeLayers={activeLayers}
                                                onToggle={onToggle}
                                                selectedValues={selectedValues}
                                                closeCalendar={() =>
                                                    handleCalendarClick(
                                                        removeAllFilters,
                                                        deselect,
                                                        toggleLayer
                                                    )
                                                }
                                            />
                                        </>
                                    )}
                                </SelectedItem>
                            );
                        }}
                    </Layers>
                )}
            </CoreFilters>
        </div>
    );
}
Calendar.propTypes = {
    openedControls: PropTypes.string,
    controlsToggle: PropTypes.func,
    map: PropTypes.object,
    setMaxZoomBackup: PropTypes.func,
    maxZoomBackup: PropTypes.number,
};
