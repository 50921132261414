import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import './MobileLayout.scss';
import Map from './Map/Map';
import SideBar from './Sidebar/SideBar';
import PropTypes from 'prop-types';
import {useParams} from 'react-router';
import {JourneyPopUp} from './Map/PopUp/JourneyPopUp';
import Header from './Header/Header';
import LayersMenu from './Map/LayersMenu/LayersMenu';
import Calendar from './Map/Calendar/Calendar';
import {getDomain} from '../../utils/envUtils';
import AppInfoModal from '../../components/modals/AppInfoModal/AppInfoModal';
import {AppContext} from '../../context';
import StreetViewModal from '../../components/modals/StreetViewModal/StreetViewModal';
import {getHash} from '../../utils/urlUtils';
import CssFilterConverter from 'css-filter-converter';

function MobileLayout({loading, project, basemaps,onToggle}) {
    let lines;
    const {t} = useTranslation();
    const {page, mode} = useParams();
    const [openedControl, setOpenedControl] = useState('');
    const [openAppInfoModal, setAppInfoModal] = useState(false);
    const search = new URLSearchParams(window.location.hash);
    const {journey, filters, selectedItem} = Object.fromEntries(
        search.entries()
    );
    const {primaryColor} = project.configs;
    if (filters) {
        lines = JSON.parse(filters).lines;
    }

    const urlColorMode = getHash('color') && `#${getHash('color')}`;

    useEffect(() => {
        const search = new URLSearchParams(
            window.location.hash.replace('#', '')
        );
        const {filters} = Object.fromEntries(
            search.entries()
        );
        if (!filters || Object.keys(JSON.parse(filters)).length === 0) {
            onToggle('type', 'Gare');
        }
    }, [onToggle]);

    useEffect(() => {
        if (project) {
            const result = CssFilterConverter.hexToFilter(urlColorMode || primaryColor);
            const root = document.documentElement;
            root?.style.setProperty(
                '--primary-color', urlColorMode || primaryColor
            );
            root?.style.setProperty(
                '--filter-svg-color', result?.color
            );
        }
    }, [project, urlColorMode, primaryColor]);

    useEffect(() => {
        if (project) {
            const d = getDomain();
            const isFirstLaunch = !localStorage.getItem(`${d}-mpr-visited`);

            if (
                !!project.configs.appInfoModalEnabled &&
                project.configs?.appInfoModalEmergence === 'onEveryLoad'
            ) {
                setAppInfoModal(true);
            }

            if (isFirstLaunch) {
                localStorage.setItem(`${d}-mpr-visited`, '1');
                if (
                    !!project.configs.appInfoModalEnabled &&
                    (project.configs?.appInfoModalEmergence === 'onFirstLoad' ||
                        !project.configs?.appInfoModalEmergence)
                ) {
                    setAppInfoModal(true);
                }
            }
        }
    }, [project]);

    const handleModalClose = () => {
        setAppInfoModal(false);
    };

    const controlsToggle = useCallback(
        (name) => {
            setOpenedControl(name);
        },
        [setOpenedControl]
    );

    if (loading) {
        return t('common:loading');
    }

    return (
        <AppContext.Consumer>
            {({streetViewModal}) => {
                return (
                    <div
                        className={`mobile-wrapper ${
                            selectedItem && !journey ? 'poi' : ''
                        }`}
                        style={{
                            background:
                                page === 'map'
                                    ? '#fff'
                                    : urlColorMode || project.configs.primaryColor,
                        }}
                    >
                        <Header
                            filter={filters}
                            projectConfigs={project.configs}
                            controlsToggle={controlsToggle}
                            mode={
                                (page === 'list' &&
                                    ((!lines && !journey) ||
                                        (selectedItem && !journey))) ||
                                (mode === 'simulator' && !journey)
                                    ? 'dark'
                                    : 'light'
                            }
                        />
                        {openedControl === 'layers' && page === 'map' && (
                            <LayersMenu
                                openedControls={openedControl}
                                controlsToggle={controlsToggle}
                            />
                        )}
                        {openedControl === 'calendar' && page === 'map' && (
                            <Calendar
                                openedControls={openedControl}
                                controlsToggle={controlsToggle}
                            />
                        )}
                        <Map
                            projectConfigs={project.configs}
                            basemaps={basemaps}
                            loading={loading}
                            controlsToggle={controlsToggle}
                            position="full"
                            active={
                                (!page || page === 'map') && mode === 'line'
                            }
                            journey={journey}
                        />

                        {journey && page === 'map' && (
                            <JourneyPopUp
                                primaryColor={urlColorMode || project.configs.primaryColor}
                            />
                        )}

                        <div
                            className={`sidebar ${
                                (!page || page === 'map') &&
                                mode !== 'simulator'
                                    ? 'page-hidden'
                                    : 'page-active'
                            }`}
                            style={{background: urlColorMode || project.configs.primaryColor}}
                        >
                            <SideBar
                                project={project}
                                loading={loading}
                                openedControl={openedControl}
                                setOpenedControl={setOpenedControl}
                            />
                        </div>
                        {openAppInfoModal && (
                            <AppInfoModal
                                open={openAppInfoModal}
                                onClose={handleModalClose}
                                project={project}
                                appInfoModal={project.configs.appInfoModal}
                            />
                        )}
                        <StreetViewModal
                            open={streetViewModal.open}
                            onClose={streetViewModal.handleClose}
                            streetView={streetViewModal.medias[0]}
                            withSidebar={true}
                        />
                    </div>
                );
            }}
        </AppContext.Consumer>
    );
}

MobileLayout.propTypes = {
    loading: PropTypes.bool,
    project: PropTypes.object,
    basemaps: PropTypes.array,
    mode: PropTypes.string,
    onToggle:PropTypes.func
};

export default MobileLayout;
