import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Button, Col, Container, Image, Row} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import SecondaryLayerTab from '../Tabs/SecondaryLayerTab';
import ListLoader from '../../loaders/ListLoader/ListLoader';
import {AppContext} from '../../../context';
import street from '../../../assets/icons/street.svg';
import {useHistory, useParams} from 'react-router';
import {getHash} from '../../../utils/urlUtils';

export const POIList = (props) => {
    const {project, handleToggle, line, selectItem, loading, item} = props;
    const {t} = useTranslation();
    const {mode, page} = useParams();
    const history = useHistory();
    const lineNumber = line.match(/[0-9]+/);
    const {setAppState} = useContext(AppContext);
    const urlColorMode = getHash('color') && `#${getHash('color')}`;
    const [showComponent, setShowComponent] = useState(false);
    const [lineURL, setLineURL] = useState('');
    const {primaryColor} = project.configs;
    const lineUrlList = useMemo(() => {
        return [
            {
                key: 'Ligne 14',
                value: 'https://www.societedugrandparis.fr/ligne-14',
            },

            {
                key: 'Ligne 15 Est',
                value: 'https://www.societedugrandparis.fr/ligne-15-est',
            },

            {
                key: 'Ligne 15 Ouest',
                value: 'https://www.societedugrandparis.fr/ligne-15-ouest',
            },

            {
                key: 'Ligne 15 Sud',
                value: 'https://www.societedugrandparis.fr/ligne-15-sud',
            },

            {
                key: 'Ligne 16',
                value: 'https://www.societedugrandparis.fr/ligne-16',
            },

            {
                key: 'Ligne 17',
                value: 'https://www.societedugrandparis.fr/ligne-17',
            },

            {
                key: 'Ligne 18',
                value: 'https://www.societedugrandparis.fr/ligne-18',
            },
        ];
    }, []);
    useEffect(() => {
        const url = lineUrlList.find((i) => i.key === line);
        setLineURL(url.value);
    }, [line, lineUrlList, setLineURL]);

    useEffect(() => {
        const id = setTimeout(() => {
            setShowComponent(true);
        }, 1000);

        return () => {
            clearTimeout(id);
        };
    }, [handleToggle, setAppState]);

    const orderedItems = useMemo(() => {
        const itemsList = item.filter(
            (_) => _.properties.type === 'Gare'
        );
        const regexp = new RegExp(line + ' - (\\d+)');
        return [...itemsList].sort((a, b) => {
            const aOrder = a.properties.order?.match(regexp)?.[1] ?? 0;
            const bOrder = b.properties.order?.match(regexp)?.[1] ?? 0;
            return aOrder - bOrder;
        });
    }, [line, item]);

    const onItemClick = (item) => {
        if (page) {
            history.push({
                pathname: `/fr/${mode}/map/`,
                hash: window.location.hash,
            });
        }
        selectItem(item.guid);
    };

    const getPoiUpperTitle = (item) => {
        const upperTitle = item.properties['upperTitle'];
        return upperTitle ? <p className={'subtitle m-0'}>{upperTitle}</p> : null;
    };
    const getPoiSubtitles = (item) => {
        const subtitle = project.configs.poiSubtitleFields
            .map((field) => {
                return item.properties[field.fieldName];
            })
            .join(',');
        return subtitle ? <p className={'subtitle m-0'}>{subtitle}</p> : null;
    };
    return (
        <main>
            {showComponent ? (
                <Container className="sidebar-page text-white">
                    <Row>
                        <Col className="line-name">
                            <div className="line-number"
                                 style={{color: urlColorMode || primaryColor}}>{lineNumber}</div>
                            {line}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {loading ? (
                                <ListLoader/>
                            ) : (
                                <ul className="list-view">
                                    {orderedItems.map((item) => (
                                        <li
                                            key={item.guid}
                                            className="passed cursor-pointer d-flex justify-content-between"
                                            onClick={() => onItemClick(item)}
                                        >
                                            <div>
                                                {getPoiUpperTitle(item)}
                                                <span>{item.properties.name}</span>

                                                {getPoiSubtitles(item)}
                                            </div>

                                            {item.properties[
                                                'vue-immersive'
                                                ] !== 'no' && (
                                                <span className="float-end">
                                                    <Image
                                                        src={street}
                                                        alt=""
                                                    />{' '}
                                                </span>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mb-2">
                            <Image src={street} alt=""/>{' '}
                            <span style={{fontSize: '12px'}}>
                                {t('immersiveView')}
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button
                                href={lineURL}
                                target={'_blank'}
                                className="nav-link w-100 cursor-pointer mt-4 btn-calculate"
                            >
                                {t('lines.seeLinePage')}
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mt-4">
                            <SecondaryLayerTab/>
                        </Col>
                    </Row>
                </Container>
            ) : (
                <ListLoader/>
            )}
        </main>
    );
};
POIList.propTypes = {
    item: PropTypes.array,
    handleToggle: PropTypes.func,
    selectItem: PropTypes.func,
    project: PropTypes.object,
    secondaryColor: PropTypes.string,
    line: PropTypes.string,
    loading: PropTypes.bool,
};
