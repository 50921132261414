import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Filters as CoreFilters } from '@mappr/react-lib';
import './Header.scss';

const Header = ({
    projectConfigs,
    backUrl,
    deselect,
    isReturnButtonExist,
    isRefreshThePage,
}) => {
    const { domain, appLogos } = projectConfigs;
    const { t } = useTranslation();
    const arrowIcon = useCallback(() => {
        return (
            <>
                <i className="arrow left" />
                <span className="text-decoration-underline ps-2">
                    {t('back')}
                </span>
            </>
        );
    }, [t]);
    const handleDeselect = (deselect) => {
        deselect();
        if (isRefreshThePage) {
            location.href = '/';
        }
    };
    return (
        <header id="header">
            {isReturnButtonExist &&
                (!backUrl ? (
                    <p className="back-btn cursor-pointer" onClick={deselect}>
                        {arrowIcon()}
                    </p>
                ) : (
                    <NavLink
                        className="back-btn"
                        to={backUrl}
                        onClick={() => handleDeselect(deselect)}
                    >
                        {arrowIcon()}
                    </NavLink>
                ))}

            <div className="logo">
                {appLogos && (
                    <CoreFilters>
                        {/* eslint-disable-next-line no-empty-pattern */}
                        {({}, { removeAllFilters }) =>
                            appLogos.map((logo) => (
                                <img
                                    key={`${logo.image.fileName}-logo`}
                                    onClick={removeAllFilters}
                                    className="cursor-pointer"
                                    src={`${process.env.REACT_APP_MPR_API_URL}/projects/${domain}/logo/${logo.image.fileName}`}
                                    alt=""
                                />
                            ))
                        }
                    </CoreFilters>
                )}
            </div>
        </header>
    );
};
Header.propTypes = {
    projectConfigs: PropTypes.object,
    backUrl: PropTypes.string,
    deselect: PropTypes.func,
    isReturnButtonExist: PropTypes.bool,
    isRefreshThePage: PropTypes.bool,
};
Header.defaultProps = {
    isReturnButtonExist: true,
};

export default Header;
