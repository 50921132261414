import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const LineTab = ({ name, selectedValues, filters, stats, onToggle }) => {
    const [sortedFilterValues, setSortedFilterValues] = useState([]);

    useEffect(() => {
        const filter = filters?.filter((filter) => filter.name === name).pop();

        const availableValues =
            filter && filter?.stats?.map((item) => item.value);
        let availableItems = [];
        if (stats) {
            availableItems = stats
                .filter((item) => availableValues?.includes(item.value))
                .sort((a, b) => {
                    return a.label_en > b.label_en ? 1 : -1;
                });
        }

        setSortedFilterValues([...availableItems]);
    }, [stats, filters, selectedValues, name]);

    return (
        <ul className="lines">
            {sortedFilterValues.map((filterItem, index) => {
                const itemId = filterItem.value
                    .toLowerCase()
                    .replace(/ /g, '-');
                const inputId = `filter-${name}-check-${itemId}`;
                return (
                    <li
                        key={'filter-lines-' + index}
                        onClick={() => {
                            onToggle(name, filterItem.value);
                        }}
                    >
                        <label
                            className="custom-control-label cursor-pointer"
                            htmlFor={inputId}
                        >
                            {filterItem.value}
                        </label>
                    </li>
                );
            })}
        </ul>
    );
};
LineTab.propTypes = {
    name: PropTypes.string,
    selectedValues: PropTypes.object,
    filters: PropTypes.array,
    stats: PropTypes.array,
    onToggle: PropTypes.func,
};
export default LineTab;
