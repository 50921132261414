import React from 'react';
import Button from 'react-bootstrap/Button';
import './StreetViewModal.scss';
import PropTypes from 'prop-types';

function StreetViewModal({
                           open,
                           streetView = {},
                           withSidebar = true,
                           onClose,
                         }) {
  let containerClass = 'order-1 col-sm-12 ';
  if (!streetView || !open) {
    return null;
  }
  return (
      <div
          className="StreetViewModal container-fluid h-100 position-absolute p-0"
          style={{
              top: 0,
              zIndex: 1031,
              width: '100%',
              transition: !open ? 'width 0.2s' : 'width 0s',
          }}
      >
          <section className="row justify-content-center h-100 m-0">
              <div
                  id="street-view-container"
                  className={`p-0 ${containerClass}`}
              >
                  <div className="street-view-wrapper d-flex w-100 h-100">
                      <iframe
                          title={streetView.embedUrl}
                          width="100%"
                          height="auto"
                          src={streetView.embedUrl}
                          frameBorder="0"
                          allowFullScreen
                      />
                  </div>
              </div>
          </section>
          {withSidebar && (
              <aside
                  className={'position-absolute top-0 right-0 w-100'}
                  onClick={onClose}
              >
                  <Button
                      variant="light"
                      className="btn-street-view mr-3 ml-3 float-end close text-muted"
                      onClick={onClose}
                  >

                  </Button>
              </aside>
          )}
      </div>
  );
}

StreetViewModal.propTypes = {
  open: PropTypes.bool,
  streetView: PropTypes.object,
  withSidebar: PropTypes.bool,
  onClose: PropTypes.func,
};
StreetViewModal.defaultProps = {
  onClose: () => {
  },
};

export default StreetViewModal;
