import React from 'react';
import './Header.scss';
import { Col, Image, Row } from 'react-bootstrap';
import searchMenu from '../../../assets/icons/menu-search.svg';
import view from '../../../assets/icons/view.svg';
import logoBlue from '../../../assets/icons/logo.svg';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Filters as CoreFilters } from '@mappr/react-lib';

const Header = ({
    projectConfigs,
    controlsToggle,
    mode,
    filter,
}) => {
    const { domain, appLogos} = projectConfigs;
    // const { t } = useTranslation();
    // const arrowIcon = useCallback(() => {
    //     return (
    //         <>
    //             <i
    //                 style={{
    //                     borderColor:
    //                         mode === 'light' ? primaryColor : secondaryColor,
    //                 }}
    //                 className="arrow left"
    //             />
    //             <span
    //                 style={{
    //                     color: mode === 'light' ? primaryColor : secondaryColor,
    //                 }}
    //                 className="ps-2"
    //             >
    //                 {t('mobile.back')}
    //             </span>
    //         </>
    //     );
    // }, [mode, primaryColor, secondaryColor, t]);
    return (
        <header
            className={`max-z-index ${
                mode === 'light' ? 'bgWhite' : 'bgPrimary'
            }`}
        >
            <CoreFilters>
                {/* eslint-disable-next-line no-empty-pattern */}
                {({}, { removeAllFilters }) => (
                    <Row className={'w-100  h-100'}>
                        <Col col={4} className="h-100">
                            {/*{isReturnButtonExist &&*/}
                            {/*    (!backUrl ? (*/}
                            {/*        <p*/}
                            {/*            className="back-btn-url cursor-pointer"*/}
                            {/*            // onClick={deselect}*/}
                            {/*        >*/}
                            {/*            {arrowIcon()}*/}
                            {/*        </p>*/}
                            {/*    ) : (*/}
                            {/*        <NavLink*/}
                            {/*            className="back-btn-url"*/}
                            {/*            to={backUrl}*/}
                            {/*            // onClick={deselect}*/}
                            {/*        >*/}
                            {/*            {arrowIcon()}*/}
                            {/*        </NavLink>*/}
                            {/*    ))}*/}
                        </Col>
                        <Col
                            sm={{ span: 4, offset: 4 }}
                            xs={{ span: 4, offset: 4 }}
                            className="logo"
                        >
                            {appLogos &&
                                appLogos.map((logo) =>
                                    mode !== 'light' ? (
                                        <img
                                            key={`${logo.image.fileName}-logo`}
                                            onClick={removeAllFilters}
                                            className="cursor-pointer"
                                            src={`${process.env.REACT_APP_MPR_API_URL}/projects/${domain}/logo/${logo.image.fileName}`}
                                            alt=""
                                        />
                                    ) : (
                                        <Image
                                            key={`${logo.image.fileName}-logo`}
                                            src={logoBlue}
                                            onClick={removeAllFilters}
                                            alt=""
                                        />
                                    )
                                )}
                        </Col>
                        <Col
                            col={4}
                            className="d-flex align-items-center justify-content-end h-100"
                        >
                            {mode === 'light' ? (
                                <div
                                    onClick={() => {
                                        controlsToggle('');
                                        if (filter) {
                                            removeAllFilters();
                                        }
                                    }}
                                >
                                    <NavLink
                                        to={{
                                            pathname: '/fr/line/list',
                                            hash: window.location.hash,
                                        }}
                                    >
                                        <Image src={searchMenu} alt=""  style={{filter:'var(--filter-svg-color)'}}/>
                                    </NavLink>
                                </div>
                            ) : (
                                <NavLink
                                    to={{
                                        pathname: '/fr/line/map',
                                        hash: window.location.hash,
                                    }}
                                >
                                    <Image src={view} alt="" />
                                </NavLink>
                            )}
                        </Col>
                    </Row>
                )}
            </CoreFilters>
        </header>
    );
};
Header.propTypes = {
    projectConfigs: PropTypes.object,
    handleMobilePage: PropTypes.func,
    backUrl: PropTypes.string,
    deselect: PropTypes.func,
    controlsToggle: PropTypes.func,
    isReturnButtonExist: PropTypes.bool,
    active: PropTypes.bool,
    mode: PropTypes.string,
    filter: PropTypes.string,
};
Header.defaultProps = {
    isReturnButtonExist: true,
};
export default Header;
