import React, { useEffect, useState } from 'react';
import { Col, Container, Image, ProgressBar, Row } from 'react-bootstrap';
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './Journey.scss';

function timeConvert(d) {
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);

    const hDisplay = h > 0 ? h + ' hrs ' : '';
    const mDisplay = m > 0 ? m + ' min ' : '';
    const sDisplay = s > 0 ? s + ' sec ' : '';
    return hDisplay + mDisplay + sDisplay;
}

// function capitalize(s) {
//     return s.toLowerCase().replace(/\b./g, function (a) {
//         return a.toUpperCase();
//     });
// }

function isWhatPercentOf(numA, numB) {
    const differences = 100 - (numA / numB) * 100;
    return 100 - differences;
}

export const Journey = ({ journeys }) => {
    const { t } = useTranslation();
    // const [calculateCo2, setCalculateCo2] = useState('');
    const [percentInVulture, setPercentInVulture] = useState(100);
    const [percentSectionCo2Sum, setPercentSectionCo2Sum] = useState(100);
    const [sectionCo2Sum, setSectionCo2Sum] = useState('');

    let inVulture = (
        journeys?.context?.car_direct_path?.co2_emission?.value / 1000
    ).toFixed(2);

    useEffect(() => {
        if (Object.keys(journeys).length > 0) {
            // if (journeys?.co2_emission && journeys?.sansCo2_emission) {
            //     let calculateCo2 = Math.round(
            //         ((journeys?.co2_emission?.value -
            //             journeys?.sansCo2_emission?.value) *
            //             100) /
            //             journeys?.co2_emission?.value
            //     );
            //     setCalculateCo2(calculateCo2);
            // }
            if (journeys?.sections) {
                const sectionCo2Sum = (
                    journeys?.sections.reduce((accumulator, object) => {
                        return accumulator + object?.co2_emission?.value;
                    }, 0) / 1000
                ).toFixed(2);
                setSectionCo2Sum(sectionCo2Sum);
            }

            if (+inVulture > +sectionCo2Sum) {
                setPercentSectionCo2Sum(
                    isWhatPercentOf(sectionCo2Sum, inVulture)
                );
            } else {
                setPercentInVulture(isWhatPercentOf(inVulture, sectionCo2Sum));
            }
        }
    }, [inVulture, journeys, sectionCo2Sum]);

    const journeyList = () => {
        const JourneyList = [];
        const allJourney = Object.keys(journeys).length
            ? journeys.sections.filter(
                  (journey) =>
                      journey.type !== 'waiting' && journey.type !== 'transfer'
              )
            : [];

        allJourney.reduce((prev, current) => {
            if (prev?.type === 'transfer' && current.type === 'waiting') {
                JourneyList.push({
                    ...prev,
                    transferWalkingDuration: prev.duration + current.duration,
                });
            } else if (
                current.type !== 'transfer' &&
                current.type !== 'waiting'
            ) {
                JourneyList.push(current);
            }
            return current;
        }, {});
        return (
            <ul className="journey-view">
                {JourneyList.map((journey, index) => {
                    if (journey.type === 'transfer') {
                        return (
                            <li
                                className="passed walk"
                                key={index + '-' + journey.id}
                            >
                                <Row>
                                    <Col md={9} sm={9} xs={9}>
                                        <EllipsisWithTooltip placement="bottom">
                                            <span className="transport-name">
                                                {' '}
                                                Changer de ligne{' '}
                                                {journey?.from?.address?.name ||
                                                    journey?.from?.name}
                                            </span>
                                        </EllipsisWithTooltip>
                                    </Col>
                                    <Col md={3} sm={3} xs={3} className="time">
                                        {timeConvert(
                                            journey?.transferWalkingDuration
                                        )}
                                    </Col>
                                </Row>
                            </li>
                        );
                    } else if (
                        journey.type === 'street_network' ||
                        journey.mode === 'walking'
                    ) {
                        return (
                            <>
                                {index === JourneyList.length - 1 && (
                                    <li
                                        className="passed walk transport-walk"
                                        key={index + '-' + journey.id + 'last'}
                                    >
                                        <Row>
                                            <Col md={9} sm={9} xs={9}>
                                                <EllipsisWithTooltip placement="bottom">
                                                    <span className="transport-name">
                                                        {' '}
                                                        {journey?.from?.name ||
                                                            journey?.from
                                                                ?.stop_area
                                                                ?.name}
                                                    </span>
                                                </EllipsisWithTooltip>
                                            </Col>
                                            <Col
                                                md={3}
                                                sm={3}
                                                xs={3}
                                                className="time"
                                            >
                                                {timeConvert(journey?.duration)}
                                            </Col>
                                        </Row>
                                    </li>
                                )}
                                <li
                                    className="passed walk"
                                    key={index + '-' + journey.id}
                                >
                                    <Row>
                                        <Col md={9} sm={9} xs={9}>
                                            <EllipsisWithTooltip placement="bottom">
                                                <span className="transport-name">
                                                    {' '}
                                                    {index + 1 ===
                                                    JourneyList.length
                                                        ? journey?.to?.address
                                                              ?.name ||
                                                          journey?.to?.name
                                                        : journey?.from?.address
                                                              ?.name ||
                                                          journey?.from?.name}
                                                </span>
                                            </EllipsisWithTooltip>
                                            <div className="font-size-12">
                                                {' '}
                                                {
                                                    journey?.from?.address
                                                        ?.administrative_regions[0]
                                                        ?.zip_code
                                                }{' '}
                                                {
                                                    journey?.from?.address
                                                        ?.administrative_regions[0]
                                                        ?.name
                                                }
                                            </div>
                                        </Col>
                                        <Col
                                            md={3}
                                            sm={3}
                                            xs={3}
                                            className="time"
                                        >
                                            {index !== JourneyList.length - 1 &&
                                                timeConvert(journey?.duration)}
                                        </Col>
                                    </Row>
                                </li>
                            </>
                        );
                    } else {
                        return (
                            <li
                                className="passed transport"
                                key={index + '-' + journey.id}
                            >
                                <Row>
                                    <Col md={9} sm={9} xs={9}>
                                        <EllipsisWithTooltip placement="bottom">
                                            <span className="transport-name">
                                                {' '}
                                                {journey?.from?.stop_point
                                                    ?.name ||
                                                    journey?.from?.stop_area
                                                        ?.name}
                                            </span>
                                        </EllipsisWithTooltip>
                                        <div>
                                            <div className="me-2 transport-box">
                                                <span className="icon type me-2 mb-1">
                                                    {journey
                                                        ?.display_informations
                                                        ?.physical_mode ===
                                                        'RER' ||
                                                    journey
                                                        ?.display_informations
                                                        ?.physical_mode ===
                                                        'Bus' ? (
                                                        <Image
                                                            src={require('../../assets/icons/picto_svg/' +
                                                                journey
                                                                    ?.display_informations
                                                                    ?.physical_mode +
                                                                '.svg')}
                                                            alt=""
                                                        />
                                                    ) : (
                                                        journey
                                                            ?.display_informations
                                                            ?.physical_mode[0]
                                                    )}
                                                </span>
                                                <span
                                                    className="icon code me-2"
                                                    style={{
                                                        background: `#${journey?.display_informations?.color}`,
                                                        fontSize:
                                                            journey
                                                                ?.display_informations
                                                                ?.code.length >
                                                            2
                                                                ? '9px'
                                                                : '12px',
                                                    }}
                                                >
                                                    {
                                                        journey
                                                            ?.display_informations
                                                            ?.code
                                                    }
                                                </span>
                                                <span className="font-size-12">
                                                    {
                                                        journey
                                                            ?.display_informations
                                                            ?.headsign
                                                    }
                                                </span>
                                            </div>
                                            {/*{journey?.stop_date_times.length >*/}
                                            {/*    0 && (*/}
                                            {/*    <div className="font-size-12 mt-1">*/}
                                            {/*        {*/}
                                            {/*            journey?.stop_date_times*/}
                                            {/*                .length*/}
                                            {/*        }{' '}*/}
                                            {/*        stations*/}
                                            {/*    </div>*/}
                                            {/*)}*/}
                                        </div>
                                    </Col>
                                    <Col md={3} sm={3} xs={3} className="time">
                                        {timeConvert(journey?.duration)}
                                    </Col>
                                </Row>
                            </li>
                        );
                    }
                })}
            </ul>
        );
    };

    return (
        <main>
            <Container fluid className="sidebar-page text-white">
                <Row>
                    <Col md={12} sm={12} xs={12}>
                        <p className="title mb-4 poi-simulator-title">
                            {t('journey.title')}
                        </p>
                    </Col>
                </Row>
                {journeys?.duration >= journeys?.sansDuration && (
                    <Row className={'mb-4'}>
                        <Col md={12} sm={12} xs={12}>
                            <span>{t('journey.noAlternative')}</span>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col md={12} sm={12} xs={12}>
                        {journeyList()}
                    </Col>
                </Row>
                <Row>
                    <Col md={12} sm={12} xs={12}>
                        <p className="title mb-4 poi-simulator-title">
                            {t('journey.travelTime')}
                        </p>
                    </Col>
                </Row>
                {journeys?.duration < journeys?.sansDuration && (
                    <Row>
                        <Col md={9} sm={9} xs={9} className="avec mb-4">
                            {t('journey.withGrandParisExpress')}
                        </Col>
                        <Col md={3} sm={3} xs={3} className="text-end">
                            {timeConvert(journeys?.duration)}
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col md={9} sm={9} xs={9} className="sans">
                        {t('journey.withoutGrandParisExpress')}
                    </Col>
                    <Col md={3} sm={3} xs={3} className="text-end">
                        {timeConvert(journeys?.sansDuration)}
                    </Col>
                </Row>
                <Row>
                    <Col md={12} sm={12} xs={12}>
                        <p className="title mb-4 poi-simulator-title">
                            {t('journey.emissionsCO2')}
                        </p>
                    </Col>
                    {/*<Col md={2} sm={2} xs={2} className="percent">*/}
                    {/*    {calculateCo2}%*/}
                    {/*</Col>*/}
                </Row>
                <Row className="mb-3">
                    <Col md={4} sm={4} xs={4} className="mb-2">
                        {t('byMetro')}
                    </Col>
                    <Col md={4} sm={4} xs={4} className="mb-2">
                        {sectionCo2Sum} kg de CO2
                    </Col>
                    <Col md={4} sm={4} xs={4}>
                        <ProgressBar now={percentSectionCo2Sum} />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={4} sm={4} xs={4}>
                        {t('byCar')}
                    </Col>
                    <Col md={4} sm={4} xs={4}>
                        {inVulture || ''} kg de CO2
                    </Col>
                    <Col md={4} sm={4} xs={4}>
                        <ProgressBar now={percentInVulture} />
                    </Col>
                </Row>
            </Container>
        </main>
    );
};
Journey.propTypes = {
    journeys: PropTypes.object,
};
