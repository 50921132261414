function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import { APP_LOAD_START, APP_LOAD_DONE, APP_LOAD_FAIL, APP_INIT_START, APP_INIT_DONE, APP_INIT_FAIL, APP_SET_LANGUAGE_START, APP_SET_LANGUAGE_DONE, APP_SET_LANGUAGE_FAIL } from '../actionTypes';
export var appLoad = function appLoad(payload) {
  return {
    type: APP_LOAD_START,
    payload: payload
  };
};
export var appLoadDone = function appLoadDone(payload) {
  return {
    type: APP_LOAD_DONE,
    payload: _extends({}, payload, {
      error: null
    })
  };
};
export var appLoadFail = function appLoadFail(payload) {
  return {
    type: APP_LOAD_FAIL,
    payload: payload
  };
};
export var appInit = function appInit(payload) {
  return {
    type: APP_INIT_START,
    payload: payload
  };
};
export var appInitDone = function appInitDone(payload) {
  return {
    type: APP_INIT_DONE,
    payload: _extends({}, payload, {
      error: null
    })
  };
};
export var appInitFail = function appInitFail(payload) {
  return {
    type: APP_INIT_FAIL,
    payload: payload
  };
};
export var appSetLanguage = function appSetLanguage(payload) {
  return {
    type: APP_SET_LANGUAGE_START,
    payload: payload
  };
};
export var appSetLanguageDone = function appSetLanguageDone(payload) {
  return {
    type: APP_SET_LANGUAGE_DONE,
    payload: _extends({}, payload, {
      error: null
    })
  };
};
export var appSetLanguageFail = function appSetLanguageFail(payload) {
  return {
    type: APP_SET_LANGUAGE_FAIL,
    payload: payload
  };
};