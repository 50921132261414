import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Map as CoreMap, useMap } from '@mappr/react-lib';
import { AppContext } from '../../../context';
import './Map.scss';
import Search from '../../../components/Search/Search';
import Calendar from '../../../components/Calendar/Calendar';
import { getDomain } from '../../../utils/envUtils';
import LayersMenu from '../../../components/Map/LayersMenu/LayersMenu';
import StreetViewModal from '../../../components/modals/StreetViewModal/StreetViewModal';
import SecondaryLayerPopUp from '../../../components/Map/SecondaryLayerPopUp/SecondaryLayerPopUp';
import { PoiPopUp } from '../../../components/Map/PoiPopUp/PoiPopUp';

function Map({
    setOpenedControl,
    projectConfigs,
    position,
    basemaps,
    isSidebarOpen,
    openedControl,
    setSideBarOpen,
}) {
    const currentBaseMap = sessionStorage.getItem(
        `${getDomain()}-mpr-base-map`
    );
    const { map } = useMap();
    const [maxZoomBackup, setMaxZoomBackup] = useState();
    const [currentBasemapInfo, setCurrentBasemapInfo] = useState();
    // eslint-disable-next-line no-unused-vars
    const [mapReady, setMapReady] = useState(false);
    const dropdownRef = useRef();
    const controlsToggle = useCallback(
        (name) => {
            setOpenedControl(name);
        },
        [setOpenedControl]
    );
    const {
        mapCenter,
        mapZoom,
        minZoom,
        maxZoom,
        mapPitch,
        minPitch,
        maxPitch,
    } = projectConfigs;
    let containerClass = 'col-12';
    if (position === 'left') {
        containerClass = 'col-8 order-1';
    } else if (position === 'right') {
        containerClass = 'col-8 order-2';
    }
    useEffect(() => {
        if (currentBaseMap) {
            const basemapInfo = basemaps.filter(
                (basemap) => basemap.name === currentBaseMap
            );
            setCurrentBasemapInfo(basemapInfo[0]);
        }
    }, [isSidebarOpen, currentBaseMap, basemaps]);

    // useEffect(() => {
    //     function handleClickOutside(event) {
    //         const header = document.querySelector('#header');
    //         if (
    //             dropdownRef.current &&
    //             !dropdownRef.current.contains(event.target) &&
    //             !header.contains(event.target)
    //         ) {
    //             setOpenedControl('');
    //             controlsToggle('');
    //         }
    //     }
    //
    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, [controlsToggle, dropdownRef, setOpenedControl]);

    const openStreetViewModal = (feature, methods) => {
        let obj = feature.properties;
        methods.setMedias([{ embedUrl: obj.URL }]);

        return false;
    };
    return (
        <AppContext.Consumer>
            {({ streetViewModal }) => {
                return (
                    <>
                        <div
                            id="map-container"
                            className={`col-12 bg-grey p-0 ${containerClass} ${
                                isSidebarOpen
                                    ? 'main-content'
                                    : 'main-content main-content--expanded'
                            }`}
                            ref={dropdownRef}
                        >
                            <div className="filter-wrapper">
                                <Search
                                    suggestionsLimit={5}
                                    geocoding={true}
                                    controlsToggle={controlsToggle}
                                    setOpenControl={setOpenedControl}
                                    openedControl={openedControl}
                                />
                                <LayersMenu
                                    openedControls={openedControl}
                                    controlsToggle={controlsToggle}
                                />
                                <Calendar
                                    map={map}
                                    openedControls={openedControl}
                                    controlsToggle={controlsToggle}
                                    setMaxZoomBackup={setMaxZoomBackup}
                                    maxZoomBackup={maxZoomBackup}
                                />
                            </div>
                            {currentBasemapInfo && (
                                <CoreMap
                                    lng={mapCenter.longitude}
                                    lat={mapCenter.latitude}
                                    zoom={mapZoom}
                                    minZoom={minZoom}
                                    maxZoom={maxZoom}
                                    pitch={mapPitch || 45}
                                    minPitch={minPitch || 0}
                                    maxPitch={maxPitch || 60}
                                    boundingBox={projectConfigs.boundingBox}
                                    SecondaryLayerPopUpComponent={
                                        SecondaryLayerPopUp
                                    }
                                    url={
                                        currentBasemapInfo &&
                                        currentBasemapInfo.url
                                    }
                                    PopUpComponent={PoiPopUp}
                                    showPopup={(poi) => poi.type !== 'Gare'}
                                    models={projectConfigs.models}
                                    openStreetViewModal={(feature) =>
                                        openStreetViewModal(feature, {
                                            setMedias:
                                                streetViewModal.setMedias,
                                        })
                                    }
                                    setMapReady={(isReady) =>
                                        setMapReady(isReady)
                                    }
                                    isBboxIncluded={false}
                                    expanded={isSidebarOpen}
                                />
                            )}

                            <StreetViewModal
                                open={streetViewModal.open}
                                onClose={() => {
                                    streetViewModal.handleClose();
                                    setSideBarOpen(true);
                                }}
                                streetView={streetViewModal.medias[0]}
                                isSidebarOpen={isSidebarOpen}
                            />
                        </div>
                    </>
                );
            }}
        </AppContext.Consumer>
    );
}

Map.propTypes = {
    position: PropTypes.oneOf(['left', 'right', 'full']).isRequired,
    loading: PropTypes.bool,
    setOpenedControl: PropTypes.func,
    projectConfigs: PropTypes.object,
    basemaps: PropTypes.array,
    isSidebarOpen: PropTypes.bool,
    openedControl: PropTypes.string,
    setSideBarOpen: PropTypes.func,
};

Map.defaultProps = {
    position: 'left',
    loading: true,
};

export default Map;
